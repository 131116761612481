export default class Normalize {
  constructor() {}
  static removePropertiesWithEmptyString(values: any = {}) {
    const newValues = { ...values }
    for (const key of Object.keys(newValues)) {
      if (newValues[key] === "") {
        delete newValues[key]
      }
    }
    return newValues
  }
}
