import React from "react"

interface Props {
  children?: React.ReactNode
}

const ChatBubbleContainer = React.forwardRef(({ children }: Props, containerRef: React.ForwardedRef<any>): JSX.Element => {
  return (
    <div ref={containerRef} className="chat-bubble-container relative flex flex-col gap-1 p-1 h-[45vh] max-h-[45vh] overflow-auto">
      {children}
    </div>
  )
})

export default ChatBubbleContainer
