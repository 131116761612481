import { createContext, useState } from "react"
import type { IHideShowColumnsContext, IRemoveItemsRepeat } from "./HideShowColumnsType"

export const HideShowColumnContext = createContext<IHideShowColumnsContext>({} as IHideShowColumnsContext)

interface Props {
  children?: React.ReactNode
  columns: Column[]
  onUpdateColumns(columns: Column[]): void
  onClose(): void
}

const HideShowColumnsProvider = ({ children, columns, onUpdateColumns, onClose }: Props) => {
  const [selectedHideColumns, setSelectedHideColumns] = useState<Column[]>([])
  const [selectedDisplayColumns, setSelectedDisplayColumns] = useState<Column[]>([])
  const [hideColumns, setHideColumns] = useState<Column[]>([...columns.filter((c) => c.isHidden)])
  const [displayColumns, setDisplayColumns] = useState<Column[]>([...columns.filter((c) => !c.isHidden)])
  const [columnProcessing, setColumnProcessing] = useState<Column | undefined>()
  const [enableButton, setEnableButton] = useState<boolean>(false)

  const removeItemsRepeat = ({ listPrimary, listSecondary }: IRemoveItemsRepeat): Column[] => {
    return listPrimary.filter((value1) => !listSecondary.some((value2) => value2.keyValue == value1.keyValue))
  }

  const transferToDisplay = () => {
    setHideColumns((prev) => {
      const newState = [...prev]
      for (const column of selectedHideColumns) {
        const _index = newState.findIndex((c) => c.keyValue === column.keyValue)
        if (_index !== -1) newState.splice(_index, 1)
      }
      return newState
    })
    setDisplayColumns((prev) => {
      const newState = [...prev]
      for (const column of selectedHideColumns) {
        if (newState.findIndex((c) => c.keyValue === column.keyValue) == -1) newState.push(column)
      }
      return newState
    })
    setSelectedHideColumns([])
    setEnableButton(true)
  }

  const transferToHide = () => {
    setDisplayColumns((prev) => {
      const newState = [...prev]
      for (const column of selectedDisplayColumns) {
        const _index = newState.findIndex((c) => c.keyValue === column.keyValue)
        if (_index !== -1) {
          newState.splice(_index, 1)
        }
      }
      return newState
    })
    setHideColumns((prev) => {
      const newState = [...prev]
      for (const column of selectedDisplayColumns) {
        if (newState.findIndex((c) => c.keyValue === column.keyValue) == -1) newState.push(column)
      }
      return newState
    })
    setSelectedDisplayColumns([])
    setEnableButton(true)
  }

  const changeCaption = () => {
    if (!columnProcessing) return
    setDisplayColumns((prev) => {
      const newState = [...prev]
      const index = newState.findIndex((c) => c.keyValue === columnProcessing.keyValue)
      if (index !== -1) {
        newState[index].caption = columnProcessing.caption
      }
      return prev
    })
    setHideColumns((prev) => {
      const newState = [...prev]
      const index = newState.findIndex((c) => c.keyValue === columnProcessing.keyValue)
      if (index !== -1) {
        newState[index].caption = columnProcessing.caption
      }
      return prev
    })
    setColumnProcessing(undefined)
    setEnableButton(true)
  }

  function unselectedAll() {
    setSelectedDisplayColumns([])
    setSelectedHideColumns([])
  }

  function emitUpdateColumns() {
    const columnsToHide = hideColumns.map((c) => ({ ...c, isHidden: true }))
    const columnsToShow = displayColumns.map((c) => ({ ...c, isHidden: false }))
    setColumnProcessing(undefined)
    onUpdateColumns([...columnsToShow, ...columnsToHide])
  }

  const values: IHideShowColumnsContext = {
    transferToDisplay,
    transferToHide,
    selectedHideColumns,
    selectedDisplayColumns,
    setSelectedHideColumns,
    setSelectedDisplayColumns,
    hideColumns,
    setHideColumns,
    displayColumns,
    setDisplayColumns,
    removeItemsRepeat,
    columnProcessing,
    setColumnProcessing,
    changeCaption,
    unselectedAll,
    emitUpdateColumns,
    enableButton,
    setEnableButton,
    onClose,
  }

  return <HideShowColumnContext.Provider value={values}>{children}</HideShowColumnContext.Provider>
}

export default HideShowColumnsProvider
