import { useEffect, useContext } from "react"
import { TranslateContext } from "@/context/TranslateContext"

const useDocumentTitle = (initialTitle: string, prefix: string = "T4 TMS") => {
  const { locale } = useContext(TranslateContext)

  function buildTitle(str: string): string {
    let _newTitle = str
    if (prefix) {
      _newTitle += ` | ${prefix}`
    }
    return _newTitle
  }

  useEffect(() => {
    document.title = buildTitle(initialTitle)
  }, [locale, initialTitle])
}

export default useDocumentTitle
