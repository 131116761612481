import { useState } from "react"
import type { ResponseEQClasses, Form, ResponseEQClass, EQClass } from "../EQClasses"
import axios from "@axios"
import { AxiosResponse } from "axios"
import { debounce } from "lodash"
export { default as InitState } from "../EQClassInitial.state"

export const useEQClasses = (filters: TableFilters = {}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [EQClasses, setEQClasses] = useState<EQClass[]>([])
  const [pagination, setPagination] = useState<Pagination>({
    count: 0,
    current_page: 1,
    per_page: 1,
    total: 1,
    total_pages: 1,
  })

  const fetchAll = async (params: TableFilters = filters) => {
    try {
      setLoading(true)
      const { data: res } = await axios.get<ResponseEQClasses>(`eqclass`, { params })
      const { status, data, pagination } = res
      if (status) {
        setPagination(pagination)
        setEQClasses(data)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const debounceFetchAll = debounce(fetchAll, 350)

  const store = async (payload: Form): Promise<AxiosResponse<ResponseEQClass>> => {
    return await axios.post<ResponseEQClass>(`eqclass`, payload)
  }

  const update = async (recordId: number, payload: Form): Promise<AxiosResponse<ResponseEQClass>> => {
    return await axios.put<ResponseEQClass>(`eqclass/${recordId}`, payload)
  }

  const destroy = async (recordId: number) => {
    try {
      setLoading(true)
      const { data: res } = await axios.delete<ResponseDelete>(`eqclass/${recordId}`)
      const { status, msg } = res
      if (!status) throw new Error(msg)
      return null
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  return {
    debounceFetchAll,
    setEQClasses,
    EQClasses,
    setLoading,
    loading,
    fetchAll,
    store,
    destroy,
    update,
    pagination,
  }
}
