import { ToastOptions } from "react-toastify"

export const KEY_TOKEN: string = "687b1008674f4eeea647eb588f7f6065"
export const KEY_PATHS: string = "paths"
export const LOCALE: string = "LOCALE"
export const TOAST_COLOR_THEME: string = "colored"

export const CONFIG_MAIN_TOAST: ToastOptions = {
  theme: TOAST_COLOR_THEME,
  position: "top-left",
  autoClose: 1000,
}

export const DEBOUNCE_START_DELAY = 400

export const MAP_STYLE_ID = import.meta.env.VITE_GOOGLE_MAP_STYLE_ID
export const MAX_ZOOM = 20
export const MIN_ZOOM = 5
export const MAP_CENTER = { lat: 39.78373, lng: -98.585968 }

export const PER_PAGES = [20, 30, 40, 50, 100, 200, 300, 500]

export { SwalConfig } from "./Swal"
export * as Views from "./views"
