import { useState } from "react"
import { AxiosResponse } from "axios"
import { debounce } from "lodash"
import type { ResponseUsers, Form, ResponseRegisterUser, ResponseUser } from "@/pages/Users/UserTypes"
import axios from "@axios"
import LaravelErrors from "@utils/laravel.errors"

export const useUsers = (filters: TableFilters = {}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [users, setUsers] = useState<User[]>([])
  const [pagination, setPagination] = useState<Pagination>({} as Pagination)

  const fetchAll = async (params: TableFilters = filters) => {
    try {
      setLoading(true)
      const { data: res } = await axios.get<ResponseUsers>(`users`, { params })
      const { status, data, pagination } = res
      if (status) {
        setPagination(pagination)
        setUsers(data)
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const debounceFetchAll = debounce(fetchAll, 350)

  const store = async (payload: Form): Promise<AxiosResponse<ResponseUser>> => {
    return await axios.post<ResponseUser>(`users`, payload)
  }

  const update = async (userId: number, payload: Form): Promise<AxiosResponse<ResponseUser>> => {
    return await axios.put<ResponseUser>(`users/${userId}`, payload)
  }

  const destroy = async (userId: number) => {
    try {
      setLoading(true)
      const { data: res } = await axios.delete<ResponseRegisterUser>(`users/${userId}`)
      const { status, msg } = res
      if (!status) {
        const _errors = LaravelErrors.toObject(msg!)
        return _errors
      }
      return res
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  return {
    debounceFetchAll,
    setUsers,
    users,
    setLoading,
    loading,
    fetchAll,
    store,
    destroy,
    update,
    pagination,
  }
}
