import React, { Dispatch, SetStateAction, createContext, useState } from "react"

import type { Commodity } from "@pages/Commodities/CommodityType"
import type { ServiceLevel } from "@pages/ServiceLevel/ServiceLevelType"
import type { Load, TSimpleTerminal } from "../LoadType"
import type { TypeAccharge } from "@pages/TypeAccharges/TypeAcchargeType"
import type { TypeTrace } from "@pages/TypeTraces/TypeTrace"
import type { Trip, TDriver, TTruck, TTrailer } from "@pages/Trips/TripType"
import { useCommodities } from "@pages/Commodities/hooks/useCommodities"
import { useServiceLevel } from "@pages/ServiceLevel/hooks/useServiceLevel"
import { useTypeAccharges } from "@pages/TypeAccharges/hooks/useTypeAccharges"
import { useTypeTraces } from "@/pages/TypeTraces/hooks/useTypeTraces"

interface ILoadContext {
  setCurrentLoad: Dispatch<SetStateAction<Load>>
  currentLoad: Load | undefined
  setCurrentTrip: Dispatch<SetStateAction<Trip>>
  currentTrip: Trip
  fetchCommodities(): void
  commodities: Commodity[]
  setCommodities: Dispatch<SetStateAction<Commodity[]>>
  fetchServiceLevels(): void
  serviceLevels: ServiceLevel[]
  fetchTypeAccharges(): void
  typAccharges: TypeAccharge[]
  setTypeAccharges: Dispatch<SetStateAction<TypeAccharge[]>>
  fetchTypeTraces(): void
  typeTraces: TypeTrace[]
  setTypeTraces: Dispatch<SetStateAction<TypeTrace[]>>
  unassignedDriver(driver: TDriver): void
  assignedDriver(driver: TDriver): void
  unassignedTruck(truck: TTruck): void
  assignedTruck(truck: TTruck): void
  unassignedTrailer(trailer: TTrailer): void
  assignedTrailer(trailer: TTrailer): void
  handlerCarrierUpdate(newLoad: Load): void
  pickupTerminal: TSimpleTerminal | undefined
  setPickupTerminal: Dispatch<SetStateAction<TSimpleTerminal | undefined>>
  nextTerminal: TSimpleTerminal | undefined
  setNextTerminal: Dispatch<SetStateAction<TSimpleTerminal | undefined>>
  deliveryTerminal: TSimpleTerminal | undefined
  setDeliveryTerminal: Dispatch<SetStateAction<TSimpleTerminal | undefined>>
}

export const LoadContext = createContext<ILoadContext>({} as ILoadContext)
const LoadContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const [currentLoad, setCurrentLoad] = useState<Load>({} as Load)
  const [currentTrip, setCurrentTrip] = useState<Trip>({} as Trip)
  const { debounceFetchAll: fetchCommodities, commodities, setCommodities } = useCommodities({ per_page: 100 })
  const { debounceFetchAll: fetchServiceLevels, serviceLevels } = useServiceLevel({ per_page: 100 })
  const { fetchAll: fetchTypeAccharges, typAccharges, setTypeAccharges } = useTypeAccharges({ per_page: 100 })
  const { fetchAll: fetchTypeTraces, typeTraces, setTypeTraces } = useTypeTraces({ per_page: 100 })
  const [pickupTerminal, setPickupTerminal] = useState<TSimpleTerminal>()
  const [nextTerminal, setNextTerminal] = useState<TSimpleTerminal>()
  const [deliveryTerminal, setDeliveryTerminal] = useState<TSimpleTerminal>()

  const unassignedDriver = (driver: TDriver) => {
    const index = currentTrip.drivers.findIndex((c) => c.id == driver.id)
    if (index == -1) return
    const newListDrivers = [...currentTrip.drivers]
    newListDrivers.splice(index, 1)
    setCurrentTrip((prev) => ({ ...prev, drivers: newListDrivers }))
  }

  const assignedDriver = (driver: TDriver) => {
    const newListDrivers = [...currentTrip.drivers]
    newListDrivers.push(driver)
    setCurrentTrip((prev) => ({ ...prev, drivers: newListDrivers }))
  }

  const unassignedTruck = (truck: TTruck) => {
    const index = currentTrip.trucks.findIndex((c) => c.id == truck.id)
    if (index == -1) return
    const newListTrucks = [...currentTrip.trucks]
    newListTrucks.splice(index, 1)
    setCurrentTrip((prev) => ({ ...prev, trucks: newListTrucks }))
  }

  const assignedTruck = (truck: TTruck) => {
    const newListTrucks = [...currentTrip.trucks]
    newListTrucks.push(truck)
    setCurrentTrip((prev) => ({ ...prev, trucks: newListTrucks }))
  }

  const unassignedTrailer = (trailer: TTrailer) => {
    const index = currentTrip.trailers.findIndex((c) => c.id == trailer.id)
    if (index == -1) return
    const newListTrailers = [...currentTrip.trailers]
    newListTrailers.splice(index, 1)
    setCurrentTrip((prev) => ({ ...prev, trailers: newListTrailers }))
  }

  const assignedTrailer = (trailer: TTrailer) => {
    const newListTrailers = [...currentTrip.trailers]
    newListTrailers.push(trailer)
    setCurrentTrip((prev) => ({ ...prev, trailers: newListTrailers }))
  }

  const handlerCarrierUpdate = (newLoad: Load) => {
    setCurrentLoad(newLoad)
  }

  const value: ILoadContext = {
    setCurrentLoad,
    currentLoad,
    fetchCommodities,
    fetchServiceLevels,
    commodities,
    serviceLevels,
    // status,
    fetchTypeAccharges,
    typAccharges,
    fetchTypeTraces,
    typeTraces,
    setCurrentTrip,
    currentTrip,
    unassignedDriver,
    assignedDriver,
    unassignedTruck,
    assignedTruck,
    unassignedTrailer,
    assignedTrailer,
    handlerCarrierUpdate,
    setTypeTraces,
    setTypeAccharges,
    setCommodities,
    pickupTerminal,
    setPickupTerminal,
    nextTerminal,
    setNextTerminal,
    deliveryTerminal,
    setDeliveryTerminal,
  }

  return <LoadContext.Provider value={value}>{children}</LoadContext.Provider>
}

export default LoadContextProvider
