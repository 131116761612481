import clsx from "clsx"
import { PiDotsSixBold } from "react-icons/pi"

import type { RowProps } from "./HideShowColumnsType"

const HideShowColumnsRow = ({ caption, field, isHeader = false, isHover = false, active = false, disabled, ...props }: RowProps) => {
  return (
    <div
      className={clsx(
        "grid w-full grid-cols-12 disabled:bg-gray-50 disabled:cursor-not-allowed text-sm text-slate-600 dark:text-slate-500 border-slate-700",
        isHeader && "bg-gray-100 dark:bg-gray-800 z-10 sticky top-0",
        !isHeader ? "cursor-move" : "cursor-default",
        !active && isHover && "hover:bg-blue-50 dark:hover:text-slate-400 dark:hover:bg-slate-700",
        active && "bg-blue-100 dark:bg-slate-700 text-blue-700 dark:!text-slate-400",
      )}
      {...props}
    >
      <div
        className={clsx(
          "col-span-7 inline-flex gap-0.5 items-center py-0.5 px-1 leading-none overflow-hidden text-start text-ellipsis capitalize justify-start",
          isHeader && "border-b dark:border-slate-700",
        )}
      >
        {!isHeader && !disabled && <PiDotsSixBold className="w-3 cursor-move" />}
        {caption}
      </div>
      <div className={clsx("col-span-5 py-0.5 px-1 text-nowrap overflow-hidden text-start text-ellipsis", isHeader && "border-b dark:border-slate-700")}>
        {field}
      </div>
    </div>
  )
}

export default HideShowColumnsRow
