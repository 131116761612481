import Axios, { AxiosResponse, InternalAxiosRequestConfig, AxiosError } from "axios"

const instance = Axios.create({ baseURL: `${import.meta.env.VITE_SOCKET_URI}/t4tmssocket/` })

// 👉 Response
const errorRequestHandler = (error: AxiosError) => Promise.reject(error)
const successRequestHandler = (config: InternalAxiosRequestConfig) => config
instance.interceptors.request.use(successRequestHandler, errorRequestHandler)

// 👉 Response
const errorResponseHandler = (error: AxiosError) => Promise.reject(error)
const successResponseHandler = (response: AxiosResponse) => response
instance.interceptors.response.use(successResponseHandler, errorResponseHandler)

export default instance
