import Axios, { AxiosResponse, InternalAxiosRequestConfig, AxiosError } from "axios"
import Cookies from "js-cookie"
import { KEY_TOKEN, LOCALE } from "@/constants"

const instance = Axios.create({ baseURL: import.meta.env.VITE_API_URL })

// 👉 Response
const errorRequestHandler = (error: AxiosError) => Promise.reject(error)
const successRequestHandler = (config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem(KEY_TOKEN)
  if (token) config.headers.Authorization = `Bearer ${token}`
  config.headers["Accept-Language"] = String(Cookies.get(LOCALE) ?? "EN").toLowerCase()
  // config.headers["Access-Control-Allow-Credentials"] = true
  // config.headers["Access-Control-Allow-Origin"] = "*"
  // config.headers["Access-Control-Allow-Methods"] = "GET,PUT,POST,DELETE,PATCH,OPTIONS"
  return config
}
instance.interceptors.request.use(successRequestHandler, errorRequestHandler)

// 👉 Response
const errorResponseHandler = (error: AxiosError) => Promise.reject(error)
const successResponseHandler = (response: AxiosResponse) => response
instance.interceptors.response.use(successResponseHandler, errorResponseHandler)

export default instance
