import * as Yup from "yup"

const email = Yup.string().email("Invalid email")
const password = Yup.string().min(2, "Too Short!").max(70, "Too Long!")

export const LoginValidationSchema = Yup.object().shape({
  password: password.required("Required"),
  email: email.required("Required"),
})

export const RegisterValidationSchema = Yup.object().shape({
  password: password.required("Required"),
  email: email.required("Required"),
})
