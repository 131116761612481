import { ChangeEvent } from "react"
import { parse, format, parseISO, isValid } from "date-fns"

export function formatPhoneNumber(value: string): string {
  const cleaned = String(value).replace(/\D/g, "")
  return (value = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"))
}

export function unformatPhoneNumber(num: string): string {
  const cleaned = ("" + num).replace(/\D/g, "")
  return cleaned
}

function deepCopy<T>(obj: T): T {
  if (obj === null || typeof obj !== "object") return obj
  const copy: any = {}
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined) {
      copy[key] = ""
    } else {
      copy[key] = deepCopy(obj[key])
    }
  }
  return copy as T
}

function formatNumber(value: string | number, options = { decimals: 2, maxLength: 7 }): string | number {
  value = String(value)
  const maxLength = !!options.decimals ? options.maxLength + 3 : options.maxLength
  if (value.length > maxLength) return value.slice(0, -1)
  let seen = false
  let seenMinus = false
  // @ts-ignore
  if (isNaN(value))
    return value
      .replace(/[.]/g, () => {
        if (seen) return ""
        seen = true
        return "."
      })
      .replace(/^-/g, () => {
        if (seenMinus) return ""
        seenMinus = true
        return "-"
      })
      .replace(/[^0-9.-]/g, "")
  if (value.indexOf(".") == -1) return value
  if (value.length - value.indexOf(".") > options.decimals) value = Number(value).toFixed(options.decimals)
  return value
}

const INPUT_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
const OUTPUT_FORMAT = "yyyy-MM-dd"
export function formatDate(currDate: string) {
  if (!currDate) return "-"
  const dateObject = parse(currDate, INPUT_FORMAT, new Date())
  return isValid(dateObject) ? format(dateObject, OUTPUT_FORMAT) : "-"
}

export function formatTime(currDate: string) {
  if (!currDate) return "-"
  const newDate = parseISO(currDate)
  if (!isValid(newDate)) return "-"
  const dateObject = parse(currDate, "yyyy-MM-dd HH:mm:ss", new Date())
  return format(dateObject, "HH:mm:ss")
}

export function timestampToDate(timestamps: string) {
  if (!timestamps) return "-"
  const dateISO = parseISO(timestamps)
  return format(dateISO, "yyyy-MM-dd HH:mm:ss")
}

export function dateToStringShort(_date: string) {
  if (!_date) return "-"
  const newDate = parseISO(_date)
  if (!isValid(newDate)) return "-"
  return format(newDate, "EEE, d MMM yyyy hh:mm a")
}

export const getInitials = (text: string, count?: number) => {
  const splitted = text.split(" ")
  const splitCount = count ?? splitted.length
  return splitted
    .map((word) => String(word).toLocaleUpperCase().charAt(0))
    .slice(0, splitCount)
    .join("")
}

export const sleep = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const toCapitalize = (str: string) => str[0].toUpperCase() + str.slice(1)

export function tryParseJSONObject(jsonString: string): boolean | any {
  try {
    const o = JSON.parse(jsonString)
    if (o && typeof o === "object") return o
    return false
  } catch (e) {
    return false
  }
  return false
}

const currency = (value: number | string): string => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(value))
}

const getValueAccordingMinMax = (ev: ChangeEvent<HTMLInputElement>): any => {
  let value: string | number = ev.target.value
  const attrMin = ev.target.min
  const attrMax = ev.target.max
  if (!attrMin && !attrMax) return value
  value = Number(value)
  if (attrMin && value < +attrMin) return attrMin
  if (attrMax && value > +attrMax) return attrMax
  return value
}

const Format = {
  toCapitalize,
  formatPhoneNumber,
  unformatPhoneNumber,
  deepCopy,
  formatNumber,
  // 👉 Format Date
  formatDate,
  formatTime,
  timestampToDate,
  dateToStringShort,
  // 👉 Format Text
  getInitials,
  sleep,

  getValueAccordingMinMax,
  currency,
}

export default Format
