function toObject(payload: LaravelValidationErrors): LaravelValidationErrorsToObject {
  const errors: LaravelValidationErrorsToObject = {}
  for (const key in payload) {
    if (Object(payload).hasOwnProperty(key) && Array.isArray(payload[key])) {
      errors[key] = payload[key][0]
    }
  }
  return errors
}

export default { toObject }
