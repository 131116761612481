export const FormRegister = {
  name: "",
  email: "",
  password: "",
  repassword: "",
  company_name: "",
  company_email: "",
  company_address: "",
  company_state: "",
  company_city: "",
  company_zip_code: "",
  company_country: "",
  new_company: 0,
  company_id: 0,
  terminal_id: 0,
}

const filters = {
  search: "",
  order_by: "id",
  asc_or_desc: "asc",
  per_page: 22,
  page: 1,
}

export default {
  FormRegister,
  filters,
}
