import useClickOutside from "@/hooks/useClickOutside"
import Tailwindcss from "@/utils/tailwind"
import { ForwardedRef, forwardRef, useState, useImperativeHandle, useRef, DetailedHTMLProps, HTMLAttributes } from "react"
import { IconType } from "react-icons"
import { useIntl } from "react-intl"
import { CSSProperties } from "styled-components"

interface IContextMenuListItems {
  Icon?: IconType
  dispatchEvent: string
  caption: string
  className?: string
}

export interface ContextMenuProps {
  textHeader?: string
  style?: CSSProperties
  onAction?(payload: { action: string }): void
  listItems: IContextMenuListItems[]
}

export interface IForwardedRef extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  openMenuContext(): void
  closeMenuContext(): void
}

const MenuContext = forwardRef((initialProps: ContextMenuProps, containerRef: ForwardedRef<IForwardedRef>): JSX.Element | null => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { onAction, textHeader, style, listItems = [] } = initialProps
  const { formatMessage: $t } = useIntl()
  const [show, setShow] = useState<boolean>(false)

  useImperativeHandle(
    containerRef,
    () => {
      return {
        openMenuContext() {
          setShow(true)
        },
        closeMenuContext() {
          setShow(false)
        },
      }
    },
    [],
  )

  useClickOutside(ref, () => show && setShow(false))

  const emitAction = (action: string) => {
    if (onAction) onAction({ action })
    setShow(false)
  }

  let mergeStyles: React.CSSProperties = {
    position: "fixed",
    height: "auto",
  }

  if (style) mergeStyles = { ...mergeStyles, ...style }

  if (!show) return null

  return (
    <div
      ref={ref}
      className="origin-top-right mt-0.5 min-w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[100] dark:ring-slate-700 dark:bg-slate-800 dark:text-slate-300"
      style={mergeStyles}
    >
      <div className="py-1">
        {!!textHeader && (
          <>
            <p className="text-blue-700 dark:text-blue-500 text-center py-1 px-4 text-xs font-bold select-none">{textHeader}</p>
            <hr className="dark:border-slate-700" />
          </>
        )}
        {!!listItems?.length ? (
          listItems.map(({ caption, Icon, dispatchEvent, className }, index) => (
            <button
              type="button"
              onClick={() => emitAction(dispatchEvent)}
              className={Tailwindcss.mergeClasses(
                "w-full flex items-center gap-1 px-4 py-2 text-xs dark:text-slate-400 hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-slate-700 dark:ring-gray-700 dark:hover:text-slate-400",
                className,
              )}
              key={index}
            >
              {Icon && <Icon className="h-[12px] w-4" />}
              {caption}
            </button>
          ))
        ) : (
          <p className="text-center p-2">{$t({ id: "No options" })}</p>
        )}
      </div>
    </div>
  )
})

export default MenuContext
