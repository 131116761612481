import { MAP_STYLE_ID } from "@/constants"

const center = {
  lat: 39.78373,
  lng: -98.585968,
}

export const restriction = {
  latLngBounds: {
    north: 49.384358,
    south: 24.396308,
    west: -124.848974,
    east: -66.885444,
  },
}

const INITIAL_CAMERA = {
  gestureHandling: "greedy",
  mapId: MAP_STYLE_ID,
  // restriction,
  maxZoom: 20,
  minZoom: 5,
  zoom: 5,
  center,
}

const IS_COLLAPSED = {
  freightList: true,
  additionalInformation: true,
}

export { INITIAL_CAMERA, IS_COLLAPSED }
