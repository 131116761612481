import HideShowColumnsProvider from "./HideShowColumnsContext"
import HideShowColumnComponent from "./HideShowColumnComponent"

interface Props {
  columns: Column[]
  onUpdateColumns(columns: Column[]): void
  onClose(): void
}

const HideShowColumns = (props: Props) => {
  return (
    <HideShowColumnsProvider {...props}>
      <HideShowColumnComponent />
    </HideShowColumnsProvider>
  )
}

export default HideShowColumns
