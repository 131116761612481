import GoogleIcon from "@/assets/googleIcon.svg?react"
import AppleIcon from "@/assets/appleIcon.svg?react"

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string
}

export const ButtonGoogle = ({ text = "Log in with Google", ...props }: Props) => {
  return (
    <button
      className="flex w-full items-center justify-center gap-3 rounded-md bg-white py-3 text-sm disabled:opacity-50 select-none text-slate-600"
      {...props}
    >
      <GoogleIcon style={{ width: "30px", height: "30px" }} />
      {text}
    </button>
  )
}

export const ButtonApple = ({ text = "Log in with Apple", ...props }: Props) => {
  return (
    <button className="flex w-full items-center justify-center gap-3 rounded-md bg-white py-3 text-sm disabled:opacity-50 select-none" {...props}>
      <AppleIcon style={{ width: "30px", height: "30px" }} />
      {text}
    </button>
  )
}
