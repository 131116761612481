import { useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import { FaX } from "react-icons/fa6"

interface Props {
  uri: string | undefined
  onClose?(): void
}

const ChatPreviewImage = ({ uri, onClose }: Props): JSX.Element | null => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === "Escape" && containerRef.current) {
        onClose && onClose()
      }
    }
    document.addEventListener("keydown", handler)
    return () => document.removeEventListener("keydown", handler)
  }, [containerRef.current])

  if (!uri) return null

  return createPortal(
    <div ref={containerRef} className="fixed overflow-hidden z-[150] inset-0" role="dialog" aria-modal="true">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 sm:block sm:p-0 text-center">
        <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"></span>
        <div className={"inline-block max-w-1/2 relative align-bottom bg-[#525659] rounded-md shadow-xl transform transition-all sm:align-middle mx-auto"}>
          <button className="absolute -right-4 -top-4 bg-[#323639] border-2 border-white rounded-full p-3 text-white" onClick={() => onClose && onClose()}>
            <FaX className="text-xs" />
          </button>
          <div className="text-start rounded-md">
            <div className="sm:mt-0 rounded-md">
              <div className="bg-transparent">
                <img src={uri} style={{ width: "100%", height: "100%" }} className="object-contain max-h-[80vh] max-w-[90vw]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("preview-image")!,
  )
}

export default ChatPreviewImage
