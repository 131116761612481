import type { TAppActionsTypes } from "@/types/App"

export const appActionsTypes: TAppActionsTypes = {
  ADD_PAGE_TO_VISITED_PAGES(state, page) {
    const newVisitedPages = [...state.visitedPages]
    const index = newVisitedPages.findIndex((c) => c.pathname == page.pathname)
    if (index != -1) newVisitedPages.splice(index, 1)
    newVisitedPages.push(page)
    return { ...state, visitedPages: newVisitedPages }
  },
  REMOVE_PAGE_OF_VISITED_PAGES(state, page) {
    const { visitedPages } = state
    return {
      ...state,
      visitedPages: visitedPages.filter((c) => c.pathname != page.pathname),
    }
  },
  SET_VISITED_PAGES(state, visitedPages) {
    return { ...state, visitedPages }
  },
  LOAD_MENU_ICONS(state, menuIcons) {
    return { ...state, menuIcons: menuIcons || [] }
  },
  UPSERT_MENU_ICONS(state, icon) {
    const newMenuIcons = [...state.menuIcons]
    const index = newMenuIcons.findIndex((c) => c.iconId == icon.iconId)
    if (index > -1) {
      const iconUpdated = { ...newMenuIcons[index] }
      iconUpdated.icon = icon.icon
      newMenuIcons.splice(index, 1, iconUpdated)
    } else {
      newMenuIcons.push(icon)
    }
    return { ...state, menuIcons: newMenuIcons }
  },
  REMOVE_MENU_ICONS(state, iconId) {
    return {
      ...state,
      menuIcons: state.menuIcons.filter((c) => c.iconId != iconId),
    }
  },
  SET_STATES(state, { refresh = false, status }) {
    if (!refresh && !!state.status.length) return { ...state }
    return { ...state, status }
  },
  SET_TERMINALS(state, { refresh = false, terminals }) {
    if (!refresh && !!state.status.length) return { ...state }
    return { ...state, terminals }
  },
}
