import { PlacePrediction } from "@/pages/Clients/ClientType"
import { LIMIT_MILES } from "@/pages/DispatchPage/components/DispatchMap/constant"
import { cloneDeep } from "lodash"

type PayloadLatLng = {
  latitude: number
  longitude: number
}

interface PropsCalculateDirections {
  origin: google.maps.LatLng
  destination: google.maps.LatLng
  points: PayloadLatLng[]
}

interface ResponseGetDetails {
  city: string
  state: string
  country: string
  zip_code: string
  address_1: string
  latitude: number | string
  longitude: number | string
}

interface PayloadBuildValidationAddress {
  address: string
  locality?: string
}

export const API_KEY = import.meta.env.VITE_GOOGLE_PLACES_KEY
export const API_VALIDATION_ADDRESS = `https://addressvalidation.googleapis.com/v1:validateAddress?key=${API_KEY}`

export const calculateDirections = async ({ origin, destination, points }: PropsCalculateDirections): Promise<google.maps.DirectionsResult> => {
  const service = new google.maps.DirectionsService()
  const directions = await service.route({
    origin: new google.maps.LatLng(origin.lat(), origin.lng()),
    destination: new google.maps.LatLng(destination.lat(), destination.lng()),
    travelMode: google.maps.TravelMode.DRIVING,
    unitSystem: google.maps.UnitSystem.IMPERIAL,
    waypoints: points.map((point) => ({
      location: new google.maps.LatLng(point.latitude, point.longitude),
      stopover: true,
    })),
  })
  return directions
}

export const getPlaceDetails = async (value: PlacePrediction): Promise<ResponseGetDetails | null> => {
  const service = new window.google.maps.places.PlacesService(document.createElement("div"))
  const promise = new Promise<ResponseGetDetails>((resolve, reject) => {
    const res: ResponseGetDetails = {} as ResponseGetDetails
    service.getDetails(
      {
        fields: ["utc_offset_minutes", "address_components", "geometry"],
        placeId: value.place_id,
      },
      (place: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) => {
        if (status !== "OK") return reject(null)
        const address1: string[] = []
        for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
          // @ts-ignore
          const componentType = component.types[0]
          switch (componentType) {
            case "street_number":
              address1.push(component.short_name)
              break
            case "route":
              address1.push(component.long_name)
              break
            case "postal_code":
              res.zip_code = component.long_name
              break
            case "locality":
              res.city = String(component.short_name).toLocaleUpperCase()
              break
            case "administrative_area_level_1":
              res.state = String(component.short_name).toLocaleUpperCase()
              break
            case "country":
              res.country = String(component.short_name).toLocaleUpperCase()
              break
          }
        }
        res.address_1 = address1.join(" ")
        res.latitude = place?.geometry?.location?.lat() || ""
        res.longitude = place?.geometry?.location?.lng() || ""
        resolve(res)
      },
    )
  })
  return promise
}

export const getPlacePredictions = (input: string = ""): Promise<google.maps.places.AutocompleteResponse> => {
  const service = new google.maps.places.AutocompleteService()
  const promise = new Promise<google.maps.places.AutocompleteResponse>((resolve, reject) => {
    service
      .getPlacePredictions({
        componentRestrictions: { country: ["us"] },
        // @ts-ignore
        fields: ["formatted_address", "geometry"],
        language: "en",
        input,
      })
      .then(resolve)
      .catch(reject)
  })
  return promise
}

export const buildPayloadValidationAddress = ({ address = "", locality = "" }: PayloadBuildValidationAddress) => ({
  address: {
    addressLines: [address],
    regionCode: "US",
    locality,
  },
})

interface NearbyPointsProps {
  pointOrigin: google.maps.LatLng
  radio?: number
  pointDestine: google.maps.LatLng
  distanceMatrixService: google.maps.DistanceMatrixService
}

export function nearbyPoints({ pointOrigin, radio = LIMIT_MILES, pointDestine }: NearbyPointsProps) {
  const distance = google.maps.geometry.spherical.computeDistanceBetween(pointOrigin, pointDestine)
  return distance <= radio * 1609.34
}

export async function nearbyPointsPerRoute<T>(data: T, options: NearbyPointsProps) {
  const { pointOrigin, radio = LIMIT_MILES, pointDestine, distanceMatrixService } = options
  const response = await distanceMatrixService.getDistanceMatrix({
    origins: [pointOrigin],
    destinations: [pointDestine],
    travelMode: google.maps.TravelMode.DRIVING,
    unitSystem: google.maps.UnitSystem.IMPERIAL,
  })
  const clone = cloneDeep(data)
  if (response.rows[0].elements[0].status == (google.maps.DirectionsStatus.OK as string)) {
    // @ts-ignore
    clone.distanceInMiles = response.rows[0].elements[0].distance.text
    // @ts-ignore
    clone.distanceInTime = response.rows[0].elements[0].duration.text
    // @ts-ignore
    clone.isNearby = response.rows[0].elements[0].distance.value <= radio * 1609.34
  } else {
    // @ts-ignore
    clone.isNearby = false
  }
  return clone
}

export function calAngle(lat1: number, lng1: number, lat2: number, lng2: number): string {
  const dy = lat2 - lat1
  const dx = Math.cos((Math.PI / 180) * lat1) * (lng2 - lng1)
  const theta = Math.atan2(dy, dx)
  const angle = (90 - (theta * 180) / Math.PI + 360) % 360
  return angle.toString()
}

interface GetMoveMarkerSmoothlyParams {
  newPosition: google.maps.LatLngLiteral
  marker: google.maps.Marker
}

export function getMoveMarkerSmoothly({ newPosition, marker }: GetMoveMarkerSmoothlyParams) {
  const oldPosition = marker.getPosition()
  if (oldPosition) {
    const data: Record<string, any> = {}
    const newAngle = calAngle(oldPosition.lat(), oldPosition.lng(), newPosition.lat, newPosition.lng)
    const icon = marker.getIcon()
    if (icon) {
      // @ts-ignore
      icon.rotation = newAngle
      data.icon = icon
    }
    if (isNaN(newPosition.lat) || isNaN(newPosition.lng)) return
    data.newPosition = newPosition
    return data
  } else {
    if (isNaN(newPosition.lat) || isNaN(newPosition.lng)) return
    return { newPosition }
  }
}

// const { icon, newPosition } = this.getMoveMarkerSmoothly({ marker, newPosition: { lat: latitude, lng: longitude } }) || {}
// if(icon) marker.setIcon(icon)
// if(newPosition) marker.setPosition(newPosition)

// export function getMarkerUnderneath(markerDrop: google.maps.LatLng, markers: google.maps.Marker[]): google.maps.Marker | null {
//   const minDistance = Infinity
//   const markerUnderneath = null
//   for (const i = 0; i < markers.length; i++) {
//     const marker = markers[i]
//     const distance = google.maps.geometry.spherical.computeDistanceBetween(markerDrop, marker.getPosition()!)
//     if (distance < minDistance && distance < 10) {
//       minDistance = distance
//       markerUnderneath = marker
//     }
//   }
//   return markerUnderneath
// }

// PlacesService.getDetails(
//   {
//     placeId: value.place_id,
//     fields: ["utc_offset_minutes", "address_components", "geometry"],
//   },
//   (place: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) => {
//     if (status !== "OK") return
//     let address1 = ""
//     let postcode = ""
//     for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
//       // @ts-ignore
//       const componentType = component.types[0]
//       switch (componentType) {
//         case "street_number":
//           address1 = `${component.long_name} ${address1}`
//           break
//         case "route":
//           address1 += component.short_name
//           break
//         case "postal_code":
//           postcode = `${component.long_name}${postcode}`
//           break
//         case "postal_code_suffix":
//           postcode = `${postcode}-${component.long_name}`
//           break
//         case "locality":
//           setFieldValue("city", String(component.short_name).toLocaleUpperCase())
//           break
//         case "administrative_area_level_1":
//           setFieldValue("state", String(component.short_name).toLocaleUpperCase())
//           break
//         case "country":
//           setFieldValue("country", String(component.short_name).toLocaleUpperCase())
//           break
//       }
//     }
//     if (!!postcode.length) setFieldValue("zip_code", String(postcode).toLocaleUpperCase())
//     setFieldValue("address_1", `${value.structured_formatting.main_text} ${value.structured_formatting.secondary_text}`)
//     setFieldValue("latitude", place?.geometry?.location?.lat() ?? "")
//     setFieldValue("longitude", place?.geometry?.location?.lng() ?? "")
//   },
// )

// loadMarkers.forEach((currMarker) => {
//   google.maps.event.addListener(currMarker, "dragend", function (event: any) {
//     const markerDrop = event.latLng
//     const markerUnderneath = getMarkerUnderneath(markerDrop, driversMarkers)
//     if(markerUnderneath) {
//       const circle = new google.maps.Circle({
//         center: markerUnderneath.getPosition(),
//         radius: 10
//       });
//       const bounds = circle.getBounds()!;
//       const ne = bounds.getNorthEast();
//       const sw = bounds.getSouthWest();
//       const polygon = new google.maps.Polygon({
//         paths: [[
//           {lat: sw.lat(), lng: sw.lng()},
//           {lat: sw.lat(), lng: ne.lng()},
//           {lat: ne.lat(), lng: ne.lng()},
//           {lat: ne.lat(), lng: sw.lng()},
//           {lat: sw.lat(), lng: sw.lng()}
//         ]]
//       });
//     }
//   })
// })

// CODE = #RM0001
// loadMarkers.forEach((currMarker) => {
//   google.maps.event.addListener(currMarker, "dragend", function (event: google.maps.StreetViewLocation) {
//     const markerDrop = event.latLng!
//     let intercepted = false
//     for (let index = 0; index < driversMarkers.length; index++) {
//       const driverMarker = driversMarkers[index]
//       const dropMarkerPosition = driverMarker.getPosition()
//       const distance = sphericalRef.current!.computeDistanceBetween(markerDrop, dropMarkerPosition!)
//       if (distance < 10) {
//         onAssignFreightToDriver(currMarker.data as LoadToRender, driverMarker.data as IDriverInformation, currMarker)
//         intercepted = true
//         break
//       }
//     }
//     if (!intercepted) {
//       if (cluster.current && mapRef.current) {
//         addRemoveMarkerOnTheCluster(cluster.current, currMarker, event)
//       }
//     }
//   })
// })
// driversMarkers.forEach((driverMarker) => {
//   google.maps.event.addListener(driverMarker, "dragend", function (event: google.maps.StreetViewLocation) {
//     if (cluster.current) {
//       addRemoveMarkerOnTheCluster(cluster.current, driverMarker, event)
//     }
//   })
// })
