import Tailwindcss from "@/utils/tailwind"
import Table from "./Table"

interface Props {
  rows: number
  cols: number
  heightSkeleton?: string
}

const TableSkeleton = ({ rows, cols, heightSkeleton = "h-6" }: Props) => {
  const classes = Tailwindcss.mergeClasses("bg-gray-200 rounded-md dark:bg-gray-700 w-full", heightSkeleton)
  return (
    <>
      {Array(rows)
        .fill(0)
        .map((_, rowIndex) => (
          <Table.Tr noHover key={rowIndex} className="animate-pulse">
            {Array(cols)
              .fill(0)
              .map((_, colIndex) => (
                <Table.Td key={colIndex}>
                  <div className={classes}></div>
                </Table.Td>
              ))}
          </Table.Tr>
        ))}
    </>
  )
}

export default TableSkeleton
