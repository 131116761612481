import { InputHTMLAttributes } from "react"

export enum TTableLoads {
  MAIN = "MAIN",
}

export enum TTableFormLoad {
  DETAILS = "DETAILS",
  MAIN = "MAIN",
}

export const COLUMNS_LOADS: Column[] = [
  {
    caption: "Trip",
    keySize: "loads_table_loads_trips_pd",
    classesChildren: "text-center",
    keyValue: "tripNumbers",
    isHidden: false,
    modelKey: "id",
    inlineSize: 70,
  },
  {
    caption: "Bill",
    keySize: "loads_table_loads_bill_number",
    field: "billNumber",
    classesChildren: "text-center",
    keyValue: "billNumber",
    isHidden: false,
    modelKey: "id",
    sortField: "bill_number",
    inlineSize: 80,
  },
  {
    caption: "Status",
    keySize: "loads_table_loads_status_name",
    classesChildren: "text-center",
    keyValue: "statusName",
    sortField: "statusCode",
    isHidden: false,
    inlineSize: 72,
  },
  {
    caption: "Pickup Name",
    keySize: "loads_table_loads_origin_name",
    field: "origin_name",
    keyValue: "originName",
    sortField: "originName",
    isHidden: false,
    inlineSize: 180,
  },
  {
    caption: "pickup date start",
    keySize: "loads_table_loads_pickup_date_start",
    keyValue: "date_pickup_start_to_string_short",
    sortField: "date_pickup_start",
    isHidden: false,
    inlineSize: 170,
  },
  {
    caption: "pickup date end",
    keySize: "loads_table_loads_pickup_date_end",
    keyValue: "date_pickup_end_to_string_short",
    sortField: "date_pickup_end",
    isHidden: false,
    inlineSize: 170,
  },
  {
    caption: "Pickup Address",
    keySize: "loads_table_loads_origin_address_1",
    field: "origin_address_1",
    keyValue: "locationOrigin",
    isHidden: false,
    inlineSize: 200,
  },
  {
    caption: "Delivery Name",
    keySize: "loads_table_loads_destination_name",
    field: "destination_name",
    keyValue: "destinationName",
    sortField: "destinationName",
    isHidden: false,
    inlineSize: 180,
  },
  {
    caption: "delivery date start",
    keySize: "loads_table_loads_delivery_date_start",
    keyValue: "date_delivery_start_to_string_short",
    sortField: "date_delivery_start",
    isHidden: false,
    inlineSize: 170,
  },
  {
    caption: "delivery date end",
    keySize: "loads_table_loads_delivery_date_end",
    keyValue: "date_delivery_end_to_string_short",
    sortField: "date_delivery_ent",
    isHidden: false,
    inlineSize: 170,
  },
  {
    caption: "Delivery Address",
    keySize: "loads_table_loads_destination_address_1",
    field: "destination_address_1",
    keyValue: "locationDestination",
    isHidden: false,
    inlineSize: 200,
  },
  {
    caption: "Driver (P/D)",
    keySize: "loads_table_loads_driver_pd",
    field: "drivers",
    classesChildren: "text-center",
    keyValue: "driverNames",
    isHidden: false,
    inlineSize: 190,
  },
  {
    caption: "Truck (P/D)",
    keySize: "loads_table_loads_truck_pd",
    field: "trucks",
    classesChildren: "text-center",
    keyValue: "truckNames",
    isHidden: false,
    inlineSize: 190,
  },
  {
    caption: "Trailer (P/D)",
    keySize: "loads_table_loads_trailer_pd",
    field: "trailers",
    classesChildren: "text-center",
    keyValue: "trailerNames",
    isHidden: false,
    inlineSize: 190,
  },
  {
    caption: "Commodity",
    keySize: "loads_table_loads_commodity_name",
    field: "commodityName",
    keyValue: "commodityName",
    isHidden: false,
    inlineSize: 90,
  },
  {
    caption: "Service Level",
    keySize: "loads_table_loads_service_level_name",
    field: "serviceLevelName",
    keyValue: "serviceLevelName",
    isHidden: false,
    inlineSize: 120,
  },
  {
    caption: "Docs",
    keySize: "loads_table_loads_docs",
    field: "documents",
    keyValue: "docs",
    isHidden: false,
    inlineSize: 70,
    disabledFilter: true,
  },
  {
    caption: "Options",
    keySize: "loads_table_loads_options",
    field: "options",
    keyValue: "options",
    isHidden: false,
    inlineSize: 70,
    disabledFilter: true,
  },
  {
    caption: "creator name",
    keySize: "loads_table_loads_creator_name",
    keyValue: "createdByName",
    isHidden: true,
    inlineSize: 120,
  },
  {
    caption: "creator email",
    keySize: "loads_table_loads_creator_email",
    keyValue: "createdByEmail",
    isHidden: true,
    inlineSize: 130,
  },
  {
    caption: "bill to name",
    keySize: "loads_table_loads_bill_to_name",
    keyValue: "billName",
    isHidden: true,
    inlineSize: 150,
  },
  {
    caption: "bill to email",
    keySize: "loads_table_loads_bill_to_email",
    keyValue: "billEmail",
    isHidden: true,
    inlineSize: 150,
  },
  {
    caption: "customer email",
    keySize: "loads_table_loads_customer_email",
    keyValue: "customerEmail",
    isHidden: true,
    inlineSize: 150,
  },
  {
    caption: "customer phone",
    keySize: "loads_table_loads_customer_phone",
    keyValue: "customerPhone",
    isHidden: true,
    inlineSize: 130,
  },
  {
    caption: "Pallets",
    keySize: "loads_table_loads_pallets",
    keyValue: "pallets",
    sortField: "pallets",
    isHidden: true,
    inlineSize: 80,
  },
  {
    caption: "Weight",
    keySize: "loads_table_loads_weight",
    keyValue: "weight",
    sortField: "weight",
    isHidden: true,
    inlineSize: 80,
  },
  {
    caption: "Charges",
    keySize: "loads_table_loads_charges",
    keyValue: "charges",
    sortField: "charges",
    isHidden: true,
    inlineSize: 80,
  },
]

export type KEY_COLUMN_DETAILS =
  | "description"
  | "commodity_name"
  | "weight"
  | "pieces"
  | "pallets"
  | "temp"
  | "_length"
  | "width"
  | "height"
  | "charge"
  | "dangerGoods"
  | "items"
  | "weight_estimate"
  | "pieces_estimate"
  | "pallets_estimate"
  | "_length_estimate"
  | "width_estimate"
  | "height_estimate"
  | "volume_estimate"
  | "cube_units"
  | "height_units"
  | "length_units"
  | "pallet_units"
  | "pieces_units"
  | "volume_units"
  | "weight_units"
  | "width_units"

export const COLUMNS_DETAILS: Column[] = [
  {
    caption: "Description",
    field: "description",
    keyValue: "description",
    keySize: "description",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Commodity",
    field: "commodity_name",
    keyValue: "commodity_name",
    keySize: "commodity_name",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Weight",
    field: "weight",
    keyValue: "weight",
    keySize: "weight",
    inlineSize: 70,
    isHidden: false,
  },
  {
    caption: "Pieces",
    field: "pieces",
    keyValue: "pieces",
    keySize: "pieces",
    inlineSize: 70,
    isHidden: false,
  },
  {
    caption: "Pallets",
    field: "pallets",
    keyValue: "pallets",
    keySize: "pallets",
    inlineSize: 70,
    isHidden: false,
  },
  {
    caption: "Temp F*",
    field: "temp",
    keyValue: "temp",
    keySize: "temp",
    inlineSize: 70,
    isHidden: false,
  },
  {
    caption: "Length",
    field: "_length",
    keyValue: "_length",
    keySize: "_length",
    inlineSize: 70,
    isHidden: false,
  },
  {
    caption: "Width",
    field: "width",
    keyValue: "width",
    keySize: "width",
    inlineSize: 70,
    isHidden: false,
  },
  {
    caption: "Height",
    field: "height",
    keyValue: "height",
    keySize: "height",
    inlineSize: 70,
    isHidden: false,
  },
  {
    caption: "Charges",
    field: "charge",
    keyValue: "charge",
    keySize: "charges",
    inlineSize: 70,
    isHidden: false,
  },
  {
    caption: "danger goods",
    field: "dangerous_goods",
    keyValue: "dangerous_goods",
    keySize: "danger_goods",
    inlineSize: 90,
    isHidden: false,
  },
  {
    caption: "high value",
    field: "high_value",
    keyValue: "high_value",
    keySize: "high_value",
    inlineSize: 90,
    isHidden: false,
  },
  {
    caption: "items",
    field: "items",
    keyValue: "items",
    keySize: "items_estimate",
    inlineSize: 50,
    isHidden: false,
  },
  {
    caption: "weight est.",
    field: "weight_estimate",
    keyValue: "weight_estimate",
    keySize: "weight_estimate",
    inlineSize: 80,
    isHidden: false,
  },
  {
    caption: "pieces est.",
    field: "pieces_estimate",
    keyValue: "pieces_estimate",
    keySize: "pieces_estimate",
    inlineSize: 80,
    isHidden: false,
  },
  {
    caption: "pallets est.",
    field: "pallets_estimate",
    keyValue: "pallets_estimate",
    keySize: "pallets_estimate",
    inlineSize: 80,
    isHidden: false,
  },
  {
    caption: "length est.",
    field: "length_estimate",
    keyValue: "length_estimate",
    keySize: "length_estimate",
    inlineSize: 80,
    isHidden: false,
  },
  {
    caption: "width est.",
    field: "width_estimate",
    keyValue: "width_estimate",
    keySize: "width_estimate",
    inlineSize: 80,
    isHidden: false,
  },
  {
    caption: "height est.",
    field: "height_estimate",
    keyValue: "height_estimate",
    keySize: "height_estimate",
    inlineSize: 80,
    isHidden: false,
  },
  {
    caption: "volume estimate",
    field: "volume_estimate",
    keyValue: "volume_estimate",
    keySize: "volume_estimate",
    inlineSize: 110,
    isHidden: false,
  },
  {
    caption: "cube units",
    field: "cube_units",
    keyValue: "cube_units",
    keySize: "cube_units",
    inlineSize: 90,
    isHidden: false,
  },
  {
    caption: "height units",
    field: "height_units",
    keyValue: "height_units",
    keySize: "height_units",
    inlineSize: 90,
    isHidden: false,
  },
  {
    caption: "length units",
    field: "length_units",
    keyValue: "length_units",
    keySize: "length_units",
    inlineSize: 90,
    isHidden: false,
  },
  {
    caption: "pallet units",
    field: "pallet_units",
    keyValue: "pallet_units",
    keySize: "pallet_units",
    inlineSize: 90,
    isHidden: false,
  },
  {
    caption: "pieces units",
    field: "pieces_units",
    keyValue: "pieces_units",
    keySize: "pieces_units",
    inlineSize: 90,
    isHidden: false,
  },
  {
    caption: "volume units",
    field: "volume_units",
    keyValue: "volume_units",
    keySize: "volume_units",
    inlineSize: 90,
    isHidden: false,
  },
  {
    caption: "weight units",
    field: "weight_units",
    keyValue: "weight_units",
    keySize: "weight_units",
    inlineSize: 90,
    isHidden: false,
  },
  {
    caption: "width units",
    field: "width_units",
    keyValue: "width_units",
    keySize: "width_units",
    inlineSize: 90,
    isHidden: false,
  },
]

export const COLUMNS_DETAILS_INPUT_ATTRS: Record<KEY_COLUMN_DETAILS, InputHTMLAttributes<HTMLInputElement>> = {
  description: {
    maxLength: 100,
    autoComplete: "off",
  },
  weight: {
    min: 0,
    max: 50000,
    maxLength: 5,
    autoComplete: "off",
  },
  pieces: {
    min: 0,
    maxLength: 3,
    autoComplete: "off",
  },
  pallets: {
    min: 0,
    maxLength: 3,
    autoComplete: "off",
  },
  temp: {
    min: -40,
    max: 120,
    autoComplete: "off",
    maxLength: 3,
    className: "text-red-600",
  },
  _length: {
    maxLength: 3,
    autoComplete: "off",
  },
  width: {
    min: 0,
    maxLength: 3,
    autoComplete: "off",
  },
  height: {
    min: 0,
    maxLength: 3,
    autoComplete: "off",
  },
  charge: {
    min: 0,
    max: 20000000,
    maxLength: 8,
    autoComplete: "off",
  },
  weight_estimate: {
    min: 0,
    max: 50000,
    maxLength: 5,
    autoComplete: "off",
  },
  pieces_estimate: {
    min: 0,
    maxLength: 3,
    autoComplete: "off",
  },
  pallets_estimate: {
    min: 0,
    maxLength: 3,
    autoComplete: "off",
  },
  _length_estimate: {
    min: 0,
    maxLength: 3,
    autoComplete: "off",
  },
  width_estimate: {
    min: 0,
    maxLength: 3,
    autoComplete: "off",
  },
  height_estimate: {
    min: 0,
    maxLength: 3,
    autoComplete: "off",
  },
  items: {
    min: 1,
    maxLength: 3,
    autoComplete: "off",
  },
  dangerGoods: {},
  commodity_name: {},
  volume_estimate: {
    min: 0,
    maxLength: 3,
    autoComplete: "off",
  },
  cube_units: {
    min: 0,
    maxLength: 3,
    autoComplete: "off",
  },
  height_units: {
    min: 0,
    maxLength: 3,
    autoComplete: "off",
  },
  length_units: {
    min: 0,
    maxLength: 3,
    autoComplete: "off",
  },
  pallet_units: {
    min: 0,
    maxLength: 3,
    autoComplete: "off",
  },
  pieces_units: {
    min: 0,
    maxLength: 3,
    autoComplete: "off",
  },
  volume_units: {
    min: 0,
    maxLength: 3,
    autoComplete: "off",
  },
  weight_units: {
    min: 0,
    maxLength: 3,
    autoComplete: "off",
  },
  width_units: {
    min: 0,
    maxLength: 3,
    autoComplete: "off",
  },
}
