import type { TripFilters } from "./TripType"

const buildFilters = (newValues: Partial<TripFilters> = {}): TripFilters => ({
  search: "",
  order_by: "",
  status_code: "",
  asc_or_desc: "",
  terminal_id: "",
  terminal_name: "",
  truck_name: "",
  trailer_name: "",
  driver_name: "",
  per_page: 0,
  page: 0,
  ...newValues,
})

const form = {
  name: "",
  status: "",
  plate: "",
  make: "",
  model: "",
  year: "",
  VIN: "",
  terminal_id: 0,
  class_id: 0,
}

const filters = buildFilters({
  order_by: "id",
  asc_or_desc: "desc",
  // terminal_id: "",
  per_page: 1000,
  page: 1,
})

export default {
  form,
  filters,
  buildFilters,
}
