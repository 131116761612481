import type { TDispatchMapActions } from "@/store/AppTypes"

export const dispatchMapActions: TDispatchMapActions = {
  UPDATE_COORDS_CENTRAL_POSITION_MAP(state, payload) {
    return {
      ...state,
      coordsCentralPositionMap: payload,
    }
  },
}
