import { Navigate } from "react-router-dom"
import React, { useContext } from "react"
import { AuthenticationContext } from "@/context/AuthenticateContext"

export const ProtectedGuestRoutes = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { isLoggedIn, getAvailableMenus } = useContext(AuthenticationContext)!
  if (isLoggedIn) {
    const [firstPage] = getAvailableMenus()
    if (firstPage) {
      return <Navigate to={firstPage} />
    }
  }
  return <>{children}</>
}
