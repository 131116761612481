import { useContext } from "react"
import { PiArrowCircleRightFill, PiArrowCircleLeftFill, PiSelectionAll, PiCheckCircleFill } from "react-icons/pi"
import { useIntl } from "react-intl"
import Swal from "sweetalert2"
import { DragDropContext, DropResult } from "react-beautiful-dnd"
import { cloneDeep } from "lodash"

import { HideShowColumnContext } from "./HideShowColumnsContext"
import { Button } from "../Button"
import HideShowColumnsSectionColumns from "./HideShowColumnsSectionColumns"
import { Input } from "../Input"
import { SwalConfig } from "@/constants"

const HideShowColumnComponent = () => {
  const {
    transferToDisplay,
    transferToHide,
    setSelectedDisplayColumns,
    setSelectedHideColumns,
    setColumnProcessing,
    unselectedAll,
    changeCaption,
    emitUpdateColumns,
    onClose,
    setDisplayColumns,
    setHideColumns,
    setEnableButton,
    selectedHideColumns,
    selectedDisplayColumns,
    hideColumns,
    displayColumns,
    columnProcessing,
    enableButton,
  } = useContext(HideShowColumnContext)
  const { formatMessage: $t } = useIntl()

  const onSubmit = async () => {
    try {
      const { isConfirmed } = await Swal.fire(SwalConfig.questionUpdateColumns({ trans: $t }))
      if (!isConfirmed) return
      emitUpdateColumns()
    } catch (error) {}
  }

  const reorder = <T extends unknown>(list: T[], startIndex: number, endIndex: number): T[] => {
    const result = cloneDeep(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result
    if (!!source.droppableId && !!destination?.droppableId) {
      if (source.droppableId === destination?.droppableId) {
        if (destination?.droppableId === "COLUMNS_TO_HIDE") {
          const values = reorder(hideColumns, result.source.index, result.destination!.index)
          setHideColumns(values)
        } else {
          const values = reorder(displayColumns, result.source.index, result.destination!.index)
          setDisplayColumns(values)
        }
      } else {
        const { index: indexSource, droppableId: droppableIdSource } = source
        if (droppableIdSource === "COLUMNS_TO_HIDE") {
          const column = hideColumns[indexSource]
          setHideColumns((prev) => {
            const newState = [...prev]
            const _index = newState.findIndex((c) => c.keyValue === column.keyValue)
            if (_index !== -1) newState.splice(_index, 1)
            return newState
          })
          setDisplayColumns((prev) => {
            const newState = [...prev]
            if (newState.findIndex((c) => c.keyValue === column.keyValue) == -1) newState.push(column)
            return newState
          })
        } else {
          const column = displayColumns[indexSource]
          setDisplayColumns((prev) => {
            const newState = [...prev]
            const _index = newState.findIndex((c) => c.keyValue === column.keyValue)
            if (_index !== -1) newState.splice(_index, 1)
            return newState
          })
          setHideColumns((prev) => {
            const newState = [...prev]
            if (newState.findIndex((c) => c.keyValue === column.keyValue) == -1) newState.push(column)
            return newState
          })
        }
      }
      setSelectedDisplayColumns([])
      setEnableButton(true)
    }
  }

  const handlers = { onDragEnd }

  return (
    <DragDropContext {...handlers}>
      <div className="px-2 pt-1 pb-2 text-xs relative">
        <div className="grid grid-cols-12 gap-1">
          <HideShowColumnsSectionColumns
            columns={hideColumns}
            registeredColumns={selectedHideColumns}
            onTransfer={setSelectedHideColumns}
            title={$t({ id: "columns to hide" })}
            areaName="COLUMNS_TO_HIDE"
          />
          <div className="col-span-2 sm:col-span-1 py-1">
            <div className="flex flex-col gap-1 justify-center items-center h-full">
              <Button
                icon
                color="danger"
                className="justify-center"
                onClick={unselectedAll}
                disabled={!selectedDisplayColumns.length && !selectedHideColumns.length}
                title={$t({ id: "Deselect" })}
              >
                <PiSelectionAll className="text-[14px]" />
              </Button>
              <Button icon className="justify-center" onClick={transferToHide} disabled={!selectedDisplayColumns.length} title={$t({ id: "move to hide" })}>
                <PiArrowCircleLeftFill className="text-[14px]" />
              </Button>
              <Button icon className="justify-center" onClick={transferToDisplay} disabled={!selectedHideColumns.length} title={$t({ id: "move to show" })}>
                <PiArrowCircleRightFill className="text-[14px]" />
              </Button>
            </div>
          </div>
          <HideShowColumnsSectionColumns
            columns={displayColumns}
            registeredColumns={selectedDisplayColumns}
            onTransfer={setSelectedDisplayColumns}
            title={$t({ id: "columns to show" })}
            areaName="COLUMNS_TO_SHOW"
          />
          <div className="col-span-12 sm:col-span-3 overflow-auto">
            <div className="flex gap-1 flex-col justify-end h-full">
              <Button color="success" className="capitalize w-full justify-center" onClick={onSubmit} disabled={!enableButton}>
                {$t({ id: "save changes" })} <PiCheckCircleFill className="text-[14px]" />
              </Button>
              <Button color="danger" className="capitalize w-full justify-center" onClick={onClose}>
                {$t({ id: "Cancel" })}
              </Button>
              <div className="border h-[calc(100%-48px)] dark:border-slate-700">
                {!!columnProcessing && (
                  <div className="py-3 px-1.5">
                    <div className="flex flex-col justify-start mb-3">
                      <label htmlFor="form-update-column-caption">{$t({ id: "Caption" })}</label>
                      <Input
                        id="form-update-column-caption"
                        value={columnProcessing.caption}
                        onChange={(ev) => setColumnProcessing((prev) => ({ ...prev!, caption: ev.target.value }))}
                        autoComplete="off"
                      />
                    </div>
                    <div className="text-end space-x-2">
                      <Button icon color="light" className="capitalize" onClick={() => setColumnProcessing(undefined)}>
                        {$t({ id: "Cancel" })}
                      </Button>
                      <Button icon color="success" className="capitalize" onClick={changeCaption}>
                        {$t({ id: "save changes" })}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DragDropContext>
  )
}

export default HideShowColumnComponent
