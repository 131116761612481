import React, { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import { FaEllipsisVertical } from "react-icons/fa6"
import clsx from "clsx"
import Tailwindcss from "@/utils/tailwind"

interface Props {
  children?: React.ReactNode
  renderLabel?: React.ReactNode
  direction?: "start" | "end"
  style?: React.CSSProperties
  customHeight?: string
  classesButton?: string
}

export function Dropdown({ children, renderLabel, direction = "end", customHeight = "h-full", classesButton, ...props }: Props) {
  const _direction = direction === "start" ? "left-0" : "right-0 !z-[50]"
  const cssClassName = Tailwindcss.mergeClasses("relative inline-block text-left", customHeight)
  return (
    <Menu as="span" className={cssClassName} {...props}>
      <span className="block h-full">
        <Menu.Button
          className={clsx(
            "h-full inline-flex w-full justify-center gap-x-1.5 rounded bg-white py-2 px-2.5 text-sm shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-slate-800 dark:text-slate-300 dark:hover:bg-slate-700 dark:ring-gray-700",
            classesButton,
          )}
        >
          {!renderLabel ? <FaEllipsisVertical className="text-xs" /> : renderLabel}
        </Menu.Button>
      </span>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={clsx(
            _direction,
            "absolute z-[100] mt-0.5 min-w-max origin-top-right divide-y divide-gray-100 rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-slate-800 dark:text-slate-300",
          )}
        >
          <span className="block py-0.5">{children}</span>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

interface OptionProps extends React.HTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode
  disabled?: boolean
  readOnly?: boolean
  className?: string
}

Dropdown.Item = ({ children, readOnly, className, ...props }: OptionProps) => (
  <Menu.Item>
    {!readOnly ? (
      ({ active }) => (
        <button
          {...props}
          className={clsx(
            "text-xs w-full h-full text-start disabled:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-400 dark:hover:bg-slate-700 dark:bg-slate-800 dark:text-slate-300",
            active ? "bg-gray-100" : "",
            "block px-1 py-2",
            className,
          )}
        >
          {children}
        </button>
      )
    ) : (
      <span className="block w-full text-xs">{children}</span>
    )}
  </Menu.Item>
)
