import { Suspense, ReactNode } from "react"
import { useIntl } from "react-intl"

interface Props {
  children: ReactNode
}
const SuspenseFallback = ({ children }: Props) => {
  const { formatMessage: $t } = useIntl()
  return <Suspense fallback={<div className="px-2 py-3 text-center text-slate-400">{$t({ id: "Loading" })}...</div>}>{children}</Suspense>
}

export default SuspenseFallback
