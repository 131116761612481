export enum STATUS {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

const form = {
  id: undefined,
  name: "",
  email: "",
  address_1: "",
  address_2: "",
  zip_code: "",
  status: "",
  terminal_id: "",
  driver_license: "",
  city: "",
  state: "",
  ssn: "",
  phone_number: "",
  user_driver_id: "",
  password: "",
  isChangePassword: false,
}

const filters = {
  search: "",
  order_by: "id",
  asc_or_desc: "asc",
  status_code: "",
  per_page: 20,
  page: 1,
}

export default {
  form,
  filters,
}
