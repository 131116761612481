export class LocalStorage {
  private static storage: Storage = window.localStorage

  static setItem<T>(key: string, value: T): void {
    const serializedValue = typeof value === "string" ? value : JSON.stringify(value)
    this.storage.setItem(key, serializedValue)
  }

  static getItem<T>(key: string, initialValue?: T): T {
    const value = this.storage.getItem(key)
    return value ? JSON.parse(value) : initialValue ?? null
  }

  static removeItem(key: string): void {
    this.storage.removeItem(key)
  }
}
