import { useState } from "react"
import { AxiosResponse } from "axios"
import { debounce } from "lodash"
import type { ResponseCarriers, Carrier, Form, ResponseCarrier } from "../CarrierType"
import axios from "@axios"

export { default as InitialState } from "../CarrierInitial.state"

export const useCarriers = (filters: TableFilters = {}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [carriers, setCarriers] = useState<Carrier[]>([])
  const [pagination, setPagination] = useState<Pagination>({
    count: 0,
    current_page: 1,
    per_page: 1,
    total: 1,
    total_pages: 1,
  })

  const fetchAll = async (params: TableFilters = filters) => {
    try {
      setLoading(true)
      const { data: res } = await axios.get<ResponseCarriers>(`carriers`, { params })
      const { status, data, pagination } = res
      if (status) {
        setPagination(pagination)
        setCarriers(data)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const debounceFetchAll = debounce(fetchAll, 350)

  const store = async (payload: Form): Promise<AxiosResponse<ResponseCarrier>> => {
    return await axios.post<ResponseCarrier>(`carriers`, payload)
  }

  const update = async (carrierId: Form["id"], payload: Omit<Form, "id">): Promise<AxiosResponse<ResponseCarrier>> => {
    return await axios.put<ResponseCarrier>(`carriers/${carrierId}`, payload)
  }

  const destroy = async (carrierId: Carrier["id"]) => {
    try {
      setLoading(true)
      const { data: res } = await axios.delete<ResponseDelete>(`carriers/${carrierId}`)
      const { status, msg } = res
      if (!status) throw new Error(msg)
      return null
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  return {
    debounceFetchAll,
    setCarriers,
    carriers,
    setLoading,
    loading,
    fetchAll,
    store,
    destroy,
    update,
    pagination,
  }
}
