import { Button } from "@/@core"
import Page404 from "@/assets/page-404.svg?react"
import { AuthenticationContext } from "@/context/AuthenticateContext"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { STATIC_PAGES } from "@/router/routeInitial.state"

export const NotFound = () => {
  const { isLoggedIn, getAvailableMenus } = useContext(AuthenticationContext)!
  const navigate = useNavigate()

  const redirectTo = () => {
    if (isLoggedIn) {
      const [firstPage] = getAvailableMenus()
      if (firstPage) {
        navigate(firstPage)
      }
    } else {
      navigate(STATIC_PAGES.SIGN_IN.path)
    }
  }

  return (
    <>
      <div className="h-screen max-w-screen-sm text-center mx-auto flex flex-col items-center justify-center gap-4">
        <Page404 className="w-full" />
        <h1 className="text-3xl">Page not found</h1>
        <Button onClick={redirectTo} color="primary">
          {isLoggedIn ? `Go System` : STATIC_PAGES.SIGN_IN.name}
        </Button>
      </div>
    </>
  )
}
