import { useState } from "react"
import { AxiosResponse } from "axios"
import { debounce } from "lodash"
import type { ResponseTrailers, Trailer, Form, ResponseTrailer, ResponseStatusHistory } from "../TrailerType"
import axios from "@axios"
import Normalize from "@/utils/normalize"

export { default as InitState } from "../TrailerInitial.state"

export const useTrailers = (filters: TableFilters = {}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [trailers, setTrailers] = useState<Trailer[]>([])
  const [pagination, setPagination] = useState<Pagination>({
    count: 0,
    current_page: 1,
    per_page: 1,
    total: 1,
    total_pages: 1,
  })

  const fetchAll = async (params: TableFilters = filters): Promise<void> => {
    try {
      setLoading(true)
      const { data: res } = await axios.get<ResponseTrailers>(`trailers`, { params: Normalize.removePropertiesWithEmptyString(params) })
      const { status, data, pagination } = res
      if (status) {
        setPagination(pagination)
        setTrailers(data)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const debounceFetchAll = debounce(fetchAll, 350)

  const store = async (payload: Form): Promise<AxiosResponse<ResponseTrailer>> => {
    return await axios.post<ResponseTrailer>(`trailers`, payload)
  }

  const update = async (trailerId: Form["id"], payload: Omit<Form, "id">): Promise<AxiosResponse<ResponseTrailer>> => {
    return await axios.put<ResponseTrailer>(`trailers/${trailerId}`, payload)
  }

  const destroy = async (trailerId: Trailer["id"]) => {
    try {
      setLoading(true)
      const { data: res } = await axios.delete<ResponseDelete>(`trailers/${trailerId}`)
      const { status, msg } = res
      if (!status) throw new Error(msg)
      return null
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const fetchStatusHistory = async (driverId?: number, queryParams?: Record<string, any>): Promise<AxiosResponse<ResponseStatusHistory>> => {
    let uri = "trailer_status"
    if (driverId) uri += `/${driverId}`
    return await axios.get<ResponseStatusHistory>(uri, {
      params: Normalize.removePropertiesWithEmptyString(queryParams),
    })
  }

  return {
    debounceFetchAll,
    setTrailers,
    trailers,
    setLoading,
    loading,
    fetchAll,
    store,
    destroy,
    update,
    pagination,
    fetchStatusHistory,
  }
}
