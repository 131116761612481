import { useEffect, useState } from "react"
import { NavLink, Outlet, useLocation } from "react-router-dom"
import { useIntl } from "react-intl"

import type { TRedirect } from "./AuthLayoutTypes"
import { DropdownLocale } from "@/@core/DropdownLocale"
import { KEY_PATHS, KEY_TOKEN } from "@/constants"

export const AuthLayout = (): JSX.Element => {
  const { formatMessage: $t } = useIntl()
  const location = useLocation()

  const initState = {
    redirectTo: "/sign-up",
    text: $t({ id: "Create an account" }),
  }

  const [redirect, setRedirect] = useState<TRedirect>(initState)

  if (localStorage.getItem(KEY_PATHS)) {
    const items = JSON.parse(localStorage.getItem(KEY_PATHS) ?? "[]")
    if (!items.length) {
      localStorage.removeItem(KEY_TOKEN)
      localStorage.removeItem(KEY_PATHS)
      localStorage.removeItem("user")
    }
  }

  useEffect(() => {
    switch (location.pathname) {
      case "/sign-up":
        setRedirect({ redirectTo: "/sign-in", text: $t({ id: "Sign In" }) })
        break
      default:
        setRedirect(initState)
        break
    }
  }, [location])

  return (
    <div className="flex min-h-screen bg-gray-100 text-gray-700 dark:text-slate-300 dark:bg-gray-900">
      <div className="flex-1 px-4">
        <div className="flex h-full flex-col">
          <header className="flex items-center justify-between py-4 font-bold">
            <div className="flex gap-2">
              <NavLink to="/" className="text-2xl">
                T4TMS
              </NavLink>
              <DropdownLocale />
            </div>
            <NavLink to={redirect.redirectTo} className="text-xs underline">
              {redirect.text}
            </NavLink>
          </header>
          <main className="grid flex-1 p-4 place-items-center">
            <Outlet />
          </main>
        </div>
      </div>
      <figure className="hidden sm:block sm:flex-1">
        <img className="h-full w-full object-cover object-right" src="/images/login.jpg" alt="Login" />
      </figure>
    </div>
  )
}
