import { useState } from "react"
import { AxiosResponse } from "axios"
import { debounce } from "lodash"

import type { ResponseServiceLevels, ResponseServiceLevel, ServiceLevel, Form } from "../ServiceLevelType"
import axios from "@axios"

export const useServiceLevel = (filters: TableFilters = {}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [serviceLevels, setServiceLevels] = useState<ServiceLevel[]>([])
  const [pagination, setPagination] = useState<Pagination>({
    count: 0,
    current_page: 1,
    per_page: 1,
    total: 1,
    total_pages: 1,
  })

  const fetchAll = async (params: TableFilters = filters) => {
    try {
      setLoading(true)
      const { data: res } = await axios.get<ResponseServiceLevels>(`service-level`, { params })
      const { status, data, pagination } = res
      if (status) {
        setPagination(pagination)
        setServiceLevels(data)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const debounceFetchAll = debounce(fetchAll, 350)

  const store = async (payload: Form): Promise<AxiosResponse<ResponseServiceLevel>> => {
    return await axios.post<ResponseServiceLevel>(`service-level`, payload)
  }

  const update = async (serviceLevelId: number, payload: Form): Promise<AxiosResponse<ResponseServiceLevel>> => {
    return await axios.put<ResponseServiceLevel>(`service-level/${serviceLevelId}`, payload)
  }

  const destroy = async (serviceLevelId: number) => {
    try {
      setLoading(true)
      await axios.delete<ResponseDelete>(`service-level/${serviceLevelId}`)
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  return {
    setServiceLevels,
    serviceLevels,
    setLoading,
    loading,
    fetchAll,
    debounceFetchAll,
    store,
    destroy,
    update,
    pagination,
  }
}
