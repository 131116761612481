import { useState } from "react"
import { AxiosResponse } from "axios"
import { debounce } from "lodash"

import type { ResponseAccharges, ResponseAccharge, TypeAccharge, Form } from "../TypeAcchargeType"
import axios from "@axios"

export const useTypeAccharges = (filters: TableFilters = {}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [typAccharges, setTypeAccharges] = useState<TypeAccharge[]>([])
  const [pagination, setPagination] = useState<Pagination>({
    count: 0,
    current_page: 1,
    per_page: 1,
    total: 1,
    total_pages: 1,
  })

  const fetchAll = async (params: TableFilters = filters) => {
    try {
      setLoading(true)
      const { data: res } = await axios.get<ResponseAccharges>(`type-accharge`, { params })
      const { status, data, pagination } = res
      if (status) {
        setPagination(pagination)
        setTypeAccharges(data)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const debounceFetchAll = debounce(fetchAll, 350)

  const store = async (payload: Form): Promise<AxiosResponse<ResponseAccharge>> => {
    return await axios.post<ResponseAccharge>(`type-accharge`, payload)
  }

  const update = async (acchargeId: number, payload: Form): Promise<AxiosResponse<ResponseAccharge>> => {
    return await axios.put<ResponseAccharge>(`type-accharge/${acchargeId}`, payload)
  }

  const destroy = async (acchargeId: number) => {
    try {
      setLoading(true)
      await axios.delete<ResponseDelete>(`type-accharge/${acchargeId}`)
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  return {
    setTypeAccharges,
    typAccharges,
    setLoading,
    loading,
    fetchAll,
    debounceFetchAll,
    store,
    destroy,
    update,
    pagination,
  }
}
