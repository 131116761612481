import { useState } from "react"
import { AxiosResponse } from "axios"
import { debounce } from "lodash"

import type {
  ResponseTrip,
  ResponseTrips,
  Trip,
  TripFilters,
  ResponseStatusHistory,
  PayloadUpdateRouteSequence,
  PayloadUpdateStatus,
  ResponseAssignZone,
} from "../TripType"
import axios from "@axios"
import Normalize from "@/utils/normalize"

export { default as InitState } from "../TripInitial.state"

export type PayloadToggleDispatch = {
  tripId: number
  dispatchId: number
}

export type PayloadAssignZone = {
  uri: "set-origin-zone" | "set-destination-zone"
  tripId: number
  zoneId: number
}

export const useTrips = (filters: TripFilters = {}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [trips, setTrips] = useState<Trip[]>([])
  const [pagination, setPagination] = useState<Pagination>({} as Pagination)
  const [loadingMiles, setLoadingMiles] = useState<boolean>(false)

  const fetchAll = async (params: TripFilters = filters): Promise<Trip[]> => {
    try {
      const normalized = { ...params }
      if (params.terminal_id == "0") delete normalized.terminal_id
      setLoading(true)
      const { data: res } = await axios.get<ResponseTrips>(`trips`, { params: Normalize.removePropertiesWithEmptyString(normalized) })
      const { status, data, pagination } = res
      if (status) {
        setPagination(pagination)
        setTrips(data)
      }
      // @ts-ignore
      return data || []
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const debounceFetchAll = debounce(fetchAll, 350)

  const store = async (payload: any): Promise<AxiosResponse<ResponseTrip>> => {
    return await axios.post<ResponseTrip>(`trips`, payload)
  }

  const update = async (payload: any, tripId: number): Promise<AxiosResponse<ResponseTrip>> => {
    return await axios.put<ResponseTrip>(`trips/${tripId}`, payload)
  }

  const findById = async (tripId: number, params: any = {}): Promise<AxiosResponse<ResponseTrip>> => {
    return await axios.get<ResponseTrip>(`trips/${tripId}`, { params })
  }

  const assign = async (payload: any): Promise<AxiosResponse<ResponseTrip>> => {
    return await axios.post<ResponseTrip>(`assign_to_trip`, payload)
  }

  const unassign = async (payload: any): Promise<AxiosResponse<ResponseTrip>> => {
    return await axios.post<ResponseTrip>(`unassign_to_trip`, payload)
  }

  const assignDispatch = async (payload: PayloadToggleDispatch): Promise<AxiosResponse<ResponseTrip>> => {
    return await axios.post<ResponseTrip>(`trip-assign-dispatch/${payload.tripId}`, {
      dispatch_id: payload.dispatchId,
    })
  }

  const unassignDispatch = async (payload: PayloadToggleDispatch): Promise<AxiosResponse<ResponseTrip>> => {
    return await axios.post<ResponseTrip>(`trip-deassign-dispatch/${payload.tripId}`, {
      dispatch_id: payload.dispatchId,
    })
  }

  const fetchStatusHistory = async (tripId?: number, queryParams?: any): Promise<AxiosResponse<ResponseStatusHistory>> => {
    let uri = "trip_status"
    if (tripId) uri += `/${tripId}`
    return await axios.get<ResponseStatusHistory>(uri, {
      params: Normalize.removePropertiesWithEmptyString(queryParams),
    })
  }

  const updateRouteSequence = async (payload: PayloadUpdateRouteSequence): Promise<void> => {
    return await axios.post("update_route_sequence", payload)
  }

  const recalculateMiles = async (tripId: number | string): Promise<void> => {
    try {
      setLoadingMiles(true)
      await axios.get(`/miles/${tripId}`)
    } catch (error) {
    } finally {
      setLoadingMiles(false)
    }
  }

  async function updateStatus(payload: PayloadUpdateStatus) {
    return await axios.post<ResponseTrip>(`trip-change-status`, payload)
  }

  const assignZone = async ({ uri, tripId, zoneId }: PayloadAssignZone): Promise<AxiosResponse<ResponseAssignZone>> => {
    return await axios.post<ResponseAssignZone>(`${uri}/${tripId}`, { zone_id: zoneId })
  }

  return {
    recalculateMiles,
    updateRouteSequence,
    updateStatus,
    fetchAll,
    debounceFetchAll,
    findById,
    trips,
    setTrips,
    pagination,
    setPagination,
    loading,
    setLoading,
    store,
    assign,
    unassign,
    update,
    fetchStatusHistory,
    assignDispatch,
    unassignDispatch,
    assignZone,
    loadingMiles,
  }
}
