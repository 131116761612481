import { FaTimes } from "react-icons/fa"

interface Props {
  url: string
  onClose(): void
}
export const PreviewPDF = ({ onClose, url }: Props) => {
  const handleCloseModalClick = () => {
    onClose()
  }
  return (
    <div className="fixed inset-0 w-screen h-screen bg-black/40 grid place-content-center z-[100]">
      <div className="w-[75vw] relative">
        <button className="absolute bg-[#353536] right-[-1.25rem] top-[-1.25rem] border-2 border-white rounded-full p-3" onClick={handleCloseModalClick}>
          <FaTimes className="text-white" aria-hidden="true" />
        </button>
        <embed src={url} type="application/pdf" className="w-full h-[85vh]" />
      </div>
    </div>
  )
}
