import { SweetAlertOptions } from "sweetalert2"

type TParams = {
  text?: string
  trans(prop: any): any
  writeOriginText?: boolean
}
export class SwalConfig {
  static questionDestroy({ text = "You won't be able to revert this!", trans }: TParams = { trans: () => {} }): SweetAlertOptions {
    return {
      title: trans({ id: "are you sure?" }),
      text: trans({ id: text }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ef4444",
      cancelButtonColor: "#6c757d",
      confirmButtonText: trans({ id: "yes, delete it!" }),
      cancelButtonText: trans({ id: "Cancel" }),
    }
  }
  static questionConfirm(
    { text = "are you sure you want to continue with this action?", trans, writeOriginText = false }: TParams = { trans: () => {} },
  ): SweetAlertOptions {
    return {
      title: trans({ id: "are you sure?" }),
      html: writeOriginText ? text : trans({ id: text }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#041731",
      cancelButtonColor: "#6c757d",
      confirmButtonText: trans({ id: "yes, continue" }),
      cancelButtonText: trans({ id: "Cancel" }),
    }
  }
  static questionUnassigned({ text = "Are you want to unassign?", trans, writeOriginText = false }: TParams = { trans: () => {} }): SweetAlertOptions {
    return {
      title: trans({ id: "are you sure?" }),
      text: writeOriginText ? text : trans({ id: text }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2563EB",
      cancelButtonColor: "#6c757d",
      confirmButtonText: trans({ id: "yes, unassign!" }),
      cancelButtonText: trans({ id: "Cancel" }),
    }
  }
  static questionAssign({ text = "", trans, writeOriginText = false }: TParams = { trans: () => {} }): SweetAlertOptions {
    return {
      title: trans({ id: "are you sure?" }),
      text: writeOriginText ? text : trans({ id: text }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2563EB",
      cancelButtonColor: "#6c757d",
      confirmButtonText: trans({ id: "yes, assign!" }),
      cancelButtonText: trans({ id: "Cancel" }),
    }
  }
  static questionChangeStatus({ text = "Are you sure you want to change status?", trans }: TParams = { trans: () => {} }): SweetAlertOptions {
    return {
      title: trans({ id: "are you sure?" }),
      text: trans({ id: text }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2563EB",
      cancelButtonColor: "#6c757d",
      confirmButtonText: trans({ id: "yes, change!" }),
      cancelButtonText: trans({ id: "Cancel" }),
    }
  }
  static questionClean({ text = "Are you sure you want to clean?", trans }: TParams = { trans: () => {} }): SweetAlertOptions {
    return {
      title: trans({ id: "are you sure?" }),
      text: trans({ id: text }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2563EB",
      cancelButtonColor: "#6c757d",
      confirmButtonText: trans({ id: "yes, clean!" }),
      cancelButtonText: trans({ id: "Cancel" }),
    }
  }
  static questionUpdateColumns({ trans }: TParams = { trans: () => {} }): SweetAlertOptions {
    return {
      title: trans({ id: "are you sure?" }),
      text: trans({ id: "Are you sure you want to update the columns?" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2563EB",
      cancelButtonColor: "#6c757d",
      confirmButtonText: trans({ id: "yes, update!" }),
      cancelButtonText: trans({ id: "Cancel" }),
    }
  }
  static questionAssignArrDocks({ trans }: TParams = { trans: () => {} }): SweetAlertOptions {
    return {
      title: trans({ id: "are you sure?" }),
      html: `
        <div>
          <p>${trans({ id: "there are unfinished pickups remaining on the trips. would you like to proceed with arriving at the dock" })}</p>
          <br />
          <p>${trans({ id: "the pickups will be unmatched from the trip" })}</p>
        </div>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2563EB",
      cancelButtonColor: "#6c757d",
      confirmButtonText: trans({ id: "yes, update!" }),
      cancelButtonText: trans({ id: "Cancel" }),
    }
  }
}
