import { useContext, useState } from "react"
import { FaRegCheckCircle, FaCheckCircle, FaEllipsisV } from "react-icons/fa"
import { useIntl } from "react-intl"
import { toast, Bounce } from "react-toastify"
import Swal from "sweetalert2"

import ChatAvatar from "@/components/Chat/ChatAvatar"
import Tailwindcss from "@/utils/tailwind"
import { Message } from "@/context/types"
import { SwalConfig } from "@/constants"
import { SocketContext } from "@/context/SocketContext"
import IconPdf from "@/assets/images/icon-pdf.png"
import ChatPreviewPdf from "@/components/Chat/ChatPreviewPdf"
import ChatPreviewImage from "@/components/Chat/ChatPreviewImage"
import clsx from "clsx"

interface Props {
  message: Message
  showControls?: boolean
  username?: string
  onEdit?(message: Message): void
  onDelete?(message: Message): void
}

const ChatBubble = ({ message, username, onDelete, onEdit, showControls = false }: Props) => {
  const { formatDate, onlineUsers } = useContext(SocketContext)
  const { formatMessage: $t } = useIntl()
  const isUser = message.type === 0
  const [showOptions, setShowOptions] = useState<boolean>(false)
  const [currentURLPdf, setCurrentURLPdf] = useState<string | undefined>(undefined)
  const [currentURLImage, setCurrentURLImage] = useState<string | undefined>(undefined)
  const classContainer = Tailwindcss.mergeClasses("flex items-start gap-1 max-w-[89%]", isUser ? "flex-row-reverse self-end" : "self-start")
  const classBubble = Tailwindcss.mergeClasses(
    "flex flex-col w-full max-w-[320px] leading-1.5 rounded py-1 text-xs inline-flex",
    isUser ? "bg-white pe-1 ps-1 dark:bg-slate-800" : "text-gray-900 ps-1 pe-1 bg-slate-200 dark:bg-slate-700 dark:text-slate-300",
  )

  const handleCopyClick = () => {
    navigator.clipboard.writeText(message.message)
    setShowOptions(false)
    toast.success($t({ id: "Copied successfully" }), {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    })
  }

  const handleEditClick = () => {
    setShowOptions(false)
    if (!onEdit) return
    onEdit(message)
  }

  const handleDestroyClick = async () => {
    setShowOptions(false)
    const { isConfirmed } = await Swal.fire(SwalConfig.questionDestroy({ trans: $t }))
    if (!isConfirmed || !onDelete) return
    onDelete(message)
  }

  const renderMessageFormat = (message: string) => {
    message = message.replace(/(https?:\/\/[^\s]+)/g, `<a style="font-style: italic; color: #6c6cff; line-height: 1" href='$1' target='_blank'>$1</a>`)
    return message
  }

  return (
    <div className={classContainer}>
      <ChatPreviewPdf uri={currentURLPdf} onClose={() => setCurrentURLPdf(undefined)} />
      <ChatPreviewImage uri={currentURLImage} onClose={() => setCurrentURLImage(undefined)} />
      {!isUser && <ChatAvatar name={username!} size="small" active={onlineUsers.some((c) => c.username === username)} />}
      <div className={classBubble}>
        <div className="flex items-center justify-between gap-2">
          <span className="text-xs font-semibold capitalize">{isUser ? $t({ id: "me" }) : username!.split(" ").slice(0, 2).join(" ")}</span>
          <div className="flex items-center gap-1">
            <span className="text-[10px] font-normal text-gray-400 italic">{formatDate(message.send_at.date)}</span>
            {isUser && (
              <span className="text-sm font-normal text-gray-500 flex gap-0.5 p-0.5">
                {message.read_at ? (
                  <FaCheckCircle className="text-xs text-green-500 dark:text-green-400" />
                ) : (
                  <FaRegCheckCircle className="text-xs text-gray-400" />
                )}
              </span>
            )}
          </div>
        </div>
        {message.content === "text" ? (
          <p className="text-sm font-normal py-0.5 break-words" dangerouslySetInnerHTML={{ __html: renderMessageFormat(message.message) }}></p>
        ) : message.content === "image" ? (
          <div role="button" className="p-2 hover:bg-gray-200 dark:hover:bg-slate-900" onClick={() => setCurrentURLImage(message.message)}>
            <img loading="lazy" src={message.message} className="rounded-lg mb-2" />
          </div>
        ) : (
          <div role="button" className="p-2 hover:bg-gray-100 dark:hover:bg-slate-900" onClick={() => setCurrentURLPdf(message.message)}>
            <img src={IconPdf} className="rounded-lg mb-2 w-20" />
          </div>
        )}
      </div>
      {isUser && (
        <div className="relative">
          <button
            className="inline-flex self-center items-center p-2 text-sm font-medium text-center border bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50 dark:bg-slate-900 dark:text-slate-300 dark:border-slate-700 dark:hover:bg-slate-800 dark:focus:ring-slate-600"
            type="button"
            onClick={() => setShowOptions((prev) => !prev)}
          >
            <FaEllipsisV />
          </button>
          {showOptions && (
            <div className="absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-20 dark:bg-slate-700 overflow-hidden">
              <ul className={clsx("text-sm text-gray-700 dark:text-gray-200", showControls && "py-2")} aria-labelledby="dropdownMenuIconButton">
                {showControls && message.content === "text" && (
                  <li>
                    <button className="text-xs block w-full text-start px-2 py-1 hover:bg-gray-100 dark:hover:bg-slate-600" onClick={handleEditClick}>
                      {$t({ id: "Edit" })}
                    </button>
                  </li>
                )}
                {message.content === "text" && (
                  <li>
                    <button className="text-xs block w-full text-start px-2 py-1 hover:bg-gray-100 dark:hover:bg-slate-600" onClick={handleCopyClick}>
                      {$t({ id: "Copy" })}
                    </button>
                  </li>
                )}
                {showControls && (
                  <li>
                    <button className="text-xs block w-full text-start px-2 py-1 hover:bg-gray-100 dark:hover:bg-slate-600" onClick={handleDestroyClick}>
                      {$t({ id: "Delete" })}
                    </button>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ChatBubble
