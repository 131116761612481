import React from "react"
import clsx from "clsx"
import { IconType } from "react-icons"
import Tailwindcss from "@/utils/tailwind"

interface DataAttributes {
  "data-tooltip-id"?: string
  "data-tooltip-content"?: string
  "data-tooltip-place"?: string
}

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string
  fontSize?: string
  IconEnd?: IconType
  IconStart?: IconType
  ButtonStart?: React.ReactNode
  ButtonEnd?: React.ReactNode
  attrRef?: React.Ref<HTMLInputElement> | null
  inputSize?: "sm" | "default"
  inlineSize?: string
  tooltipConfig?: DataAttributes
  inputType?: "SELECT" | "INPUT"
}

export const Input = ({
  className,
  fontSize = "text-sm",
  IconEnd,
  IconStart,
  ButtonStart,
  ButtonEnd,
  type = "text",
  attrRef,
  inputSize = "default",
  inlineSize = "",
  tooltipConfig = {},
  inputType = "INPUT",
  ...props
}: InputProps): JSX.Element => {
  const __input_size__ = {
    sm: "min-h-[24px] h-[24px] max-h-[24px]",
    default: "min-h-[34px] h-[34px] max-h-[24px]",
  }[inputSize]
  let inputBaseClass = Tailwindcss.mergeClasses(
    "block m-0 w-full overflow-hidden text-slate-800 font-normal rounded px-3 py-1 border border-gray-300 placeholder:text-gray-400 placeholder:font-normal focus:border-blue-400 sm:leading-6 outline-none placeholder:transition-all focus:placeholder:opacity-60 placeholder:text-xs bg-transparent dark:bg-transparent dark:border-slate-600 focus:placeholder:translate-x-1 dark:text-slate-400 disabled:bg-gray-100 disabled:dark:bg-slate-950 dark:disabled:text-gray-400",
    __input_size__,
  )
  inputBaseClass = Tailwindcss.mergeClasses(inputBaseClass, className)
  const iconBaseClass = "text-gray-400 absolute top-0 h-full !text-lg pointer-events-none"
  const dynamicPadding = `${!!IconStart ? "ps-7" : ""} ${!!IconEnd ? "pe-7" : ""}`
  const dynamicBorderInput = `${!!ButtonStart ? "rounded-l-none !border-l-0" : ""} ${!!ButtonEnd ? "rounded-r-none !border-r-0" : undefined}`
  const cssClassesParent = Tailwindcss.mergeClasses(`flex h-full`, __input_size__)
  const cssClassesSemiParent = Tailwindcss.mergeClasses(`relative w-full h-full`, __input_size__)
  return (
    <div className={clsx(cssClassesParent, inlineSize)}>
      {!!ButtonStart && ButtonStart}
      <div className={cssClassesSemiParent}>
        {!!IconStart && <IconStart className={clsx(iconBaseClass, "left-1.5")} />}
        {/* @ts-ignore */}
        <input
          ref={attrRef}
          onKeyDown={(e) => e.which === 13 && e.preventDefault()}
          type={type}
          // read-only:cursor-pointer
          className={clsx(
            inputBaseClass,
            fontSize,
            dynamicPadding,
            dynamicBorderInput,
            inputType === "SELECT" ? "read-only:cursor-pointer" : "read-only:cursor-default",
          )}
          {...props}
          {...tooltipConfig}
        />
        {!!IconEnd && <IconEnd className={clsx(iconBaseClass, "right-1.5")} />}
      </div>
      {!!ButtonEnd && ButtonEnd}
    </div>
  )
}
