import { ILoaders } from "./DispatchType"

const filterFreightBills = {
  p: true,
  d: true,
  suppressCompleted: false,
  suppressAttempts: false,
}

const loaders: ILoaders = {
  freights: false,
  assigningFreight: false,
  unassigningFreight: false,
  tripFreights: false,
  creatingTrip: false,
  trips: false,
}

export default {
  filterFreightBills,
  loaders,
}
