import React, { ForwardedRef } from "react"
import clsx from "clsx"
import Tailwindcss from "@/utils/tailwind"

interface CellProp extends React.HTMLAttributes<HTMLTableCellElement> {
  children?: React.ReactNode
  colSpan?: number
  rowSpan?: number
  className?: string
  style?: Record<string, string>
}

interface TrProp {
  children?: React.ReactNode
  className?: string
  onClick?: (ev: React.MouseEvent<HTMLTableRowElement>) => void
  onDoubleClick?: (ev: React.MouseEvent<HTMLTableRowElement>) => void
  noHover?: boolean
  role?: string
  style?: React.CSSProperties
}

const Thead = ({ children, ...props }: { children?: React.ReactNode; style?: React.CSSProperties }): JSX.Element => <thead {...props}>{children}</thead>
const Tbody = ({ children }: { children?: React.ReactNode }): JSX.Element => <tbody>{children}</tbody>
const Th = ({ children, className, colSpan, rowSpan, style }: CellProp): JSX.Element => {
  const newClassName = Tailwindcss.mergeClasses(
    `p-1 group/table text-xs text-start bg-gray-100 dark:bg-slate-800 text-slate-600 dark:text-slate-400`,
    className ?? "",
  )
  return (
    <th colSpan={colSpan} rowSpan={rowSpan} className={clsx(newClassName, "align-middle first-of-type:rounded-ss last-of-type:rounded-se")} style={style}>
      {children}
    </th>
  )
}
const Td = ({ children, className, colSpan, rowSpan, style }: CellProp): JSX.Element => (
  <td colSpan={colSpan} rowSpan={rowSpan} className={clsx("p-0.5 text-xs align-middle", className)} style={style}>
    {children}
  </td>
)
const Tr = React.forwardRef(
  ({ children, className, onClick = () => {}, onDoubleClick = () => {}, noHover = false, ...props }: TrProp, ref: ForwardedRef<any>): JSX.Element => (
    <tr
      ref={ref}
      onDoubleClick={onDoubleClick}
      className={clsx("border-b dark:border-slate-700", noHover ? "" : "component", className)}
      onClick={onClick}
      {...props}
    >
      {children}
    </tr>
  ),
)
const Table = ({ children, className }: { children?: React.ReactNode; className?: string }): JSX.Element => {
  return (
    <div className={clsx("max-w-screen-sm sm:max-w-full overflow-auto mt-1 relative", className)}>
      <table className="w-full">{children}</table>
    </div>
  )
}

export default {
  Thead,
  Tbody,
  Th,
  Td,
  Tr,
  Table,
}
