import { Navigate } from "react-router-dom"
import React, { useContext } from "react"
import { STATIC_PAGES } from "@/router/routeInitial.state"
import { AuthenticationContext } from "@/context/AuthenticateContext"

export const ProtectedAuthRoutes = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { isLoggedIn } = useContext(AuthenticationContext)!
  if (!isLoggedIn) return <Navigate to={STATIC_PAGES.SIGN_IN.path} />
  return <>{children}</>
}
