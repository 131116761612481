const form = {
  id: "",
  name: "",
  email: "",
  address_1: "",
  address_2: "",
  status: true,
  city: "",
  state: "",
  zip_code: "",
  contact_email: "",
  contact_name: "",
  country: "",
  phone_number: "",
  mc_number: "",
  website: "",
  active: true,
}

const filters = {
  search: "",
  order_by: "id",
  asc_or_desc: "asc",
  per_page: 22,
  page: 1,
}

export default {
  form,
  filters,
}
