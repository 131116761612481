export enum STATUS {
  AVAIL = "AVAIL",
}

const form = {
  id: undefined,
  name: "",
  plate: "",
  VIN: "",
  make: "",
  model: "",
  year: "",
  status: "",
  terminal_id: "",
}

const filters = {
  search: "",
  order_by: "id",
  asc_or_desc: "asc",
  status_code: "",
  per_page: 22,
  page: 1,
}

export default {
  form,
  filters,
  STATUS,
}
