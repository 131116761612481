import { getInitials } from "@/utils/formats"
import Tailwindcss from "@/utils/tailwind"

type Props = {
  name: string
  active?: boolean
  size?: "normal" | "large" | "small"
}

const ChatAvatar = ({ name, active = false, size = "normal" }: Props): JSX.Element => {
  let classes = Tailwindcss.mergeClasses(
    "flex items-center justify-center rounded-full font-bold font-serif user-select-auto ring-1",
    active ? "ring-green-300 bg-green-100 text-green-500" : "ring-blue-200 bg-blue-100 text-blue-950",
  )
  classes = Tailwindcss.mergeClasses(
    classes,
    size == "normal" ? "w-7 h-7 min-w-7 min-h-7 text-[11px]" : size == "large" ? "w-10 h-10 min-w-10 min-h-10 text-xs" : "w-6 h-6 min-w-6 min-h-6 text-[10px]",
  )
  return (
    <div className={classes}>
      {/* <img className="w-6 h-6 rounded-full" src={avatar} alt="Name " /> */}
      {getInitials(name, 2)}
    </div>
  )
}

export default ChatAvatar
