const form = {
  name: "",
  status: "",
  plate: "",
  make: "",
  model: "",
  year: "",
  VIN: "",
  terminal_id: 0,
  class_id: 0,
}

const filters = {
  search: "",
  order_by: "id",
  asc_or_desc: "asc",
  status_code: "",
  per_page: 22,
  page: 1,
}

export default {
  form,
  filters,
}
