import { useState } from "react"
import type { Form, ResponseTruck, ResponseTrucks, Truck, ResponseStatusHistory } from "@/pages/Trucks/TruckType"
import axios from "@axios"
import { AxiosResponse } from "axios"
import { debounce } from "lodash"
import Normalize from "@/utils/normalize"

export { default as InitState } from "../TruckInitial.state"

export const useTrucks = (filters: TableFilters = {}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [trucks, setTrucks] = useState<Truck[]>([])
  const [pagination, setPagination] = useState<Pagination>({} as Pagination)

  const fetchAll = async (params: TableFilters = filters): Promise<void> => {
    try {
      setLoading(true)
      const { data: res } = await axios.get<ResponseTrucks>(`truck`, { params: Normalize.removePropertiesWithEmptyString(params) })
      const { status, data, pagination } = res
      if (status) {
        setPagination(pagination)
        setTrucks(data)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const debounceFetchAll = debounce(fetchAll, 350)

  const store = async (payload: Form): Promise<AxiosResponse<ResponseTruck>> => {
    return await axios.post<ResponseTruck>(`truck`, payload)
  }

  const update = async (truckId: number, payload: Form): Promise<AxiosResponse<ResponseTruck>> => {
    return await axios.put<ResponseTruck>(`truck/${truckId}`, payload)
  }

  const destroy = async (truckId: number) => {
    try {
      setLoading(true)
      const { data: res } = await axios.delete<ResponseDelete>(`truck/${truckId}`)
      const { status, msg } = res
      if (!status) throw new Error(msg)
      return null
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const fetchStatusHistory = async (driverId?: number, queryParams?: Record<string, any>): Promise<AxiosResponse<ResponseStatusHistory>> => {
    let uri = "truck_status"
    if (driverId) uri += `/${driverId}`
    return await axios.get<ResponseStatusHistory>(uri, {
      params: Normalize.removePropertiesWithEmptyString(queryParams),
    })
  }

  return {
    debounceFetchAll,
    setTrucks,
    trucks,
    setLoading,
    loading,
    fetchAll,
    store,
    destroy,
    update,
    pagination,
    fetchStatusHistory,
  }
}
