import { DraggableProvided, DraggableProvidedDraggableProps, DraggableStateSnapshot } from "react-beautiful-dnd"

export const getStyled = (snapshot: DraggableStateSnapshot, draggableProps: DraggableProvided) => {
  const $html = document.querySelector("html")!
  let background = "white"
  let color = "rgb(71, 85, 105)"
  if ($html.classList.contains("dark")) {
    background = "#172554"
    color = "rgb(203, 213, 225)"
  }
  return {
    rotate: snapshot.isDragging ? "0.3deg" : "0deg",
    background: snapshot.isDragging ? background : "transparent",
    color: snapshot.isDragging ? color : "inherit",
    opacity: snapshot.isDragging ? 0.2 : 1,
    ...draggableProps.draggableProps.style,
  }
}

export const getItemStyled = (isDragging: boolean, draggableStyle: DraggableProvidedDraggableProps) => ({
  ...draggableStyle.style,
  color: isDragging ? "#4B5563" : "inherit",
  ...draggableStyle,
})

export const getValueFromLocalStorage = (key: string, initValues: TableStructure[]): TableStructure[] => {
  try {
    const stored = localStorage.getItem(`ORDER_COLUMNS_${key}`)
    if (stored) {
      return JSON.parse(stored)
    } else {
      return initValues
    }
  } catch (error) {
    return initValues
  }
}

export const convertSeconds = (_seconds: number): Record<string, string> => {
  const hours = Math.floor(_seconds / 3600)
  const minutes = Math.floor((_seconds % 3600) / 60)
  const seconds = _seconds % 60
  return {
    hours: String(hours).padStart(2, "0"),
    minutes: String(minutes).padStart(2, "0"),
    seconds: String(seconds).padStart(2, "0"),
  }
}

const mergeAttributes = (storedCol: Column, initCols: Column[]): Column => {
  const originalItem = initCols.find((c) => c.keyValue === storedCol.keyValue)
  return {
    ...storedCol,
    classesChildren: originalItem?.classesChildren,
    classes: originalItem?.classes ?? "",
    sortField: originalItem?.sortField,
    field: originalItem?.field,
  }
}

export const mergeColumns = (initColumns: Column[], storedColumns: Column[]): Column[] => {
  let newColumns: Column[] = []
  const newColumnsToAdd = initColumns.filter((a) => !storedColumns.some((b) => b.keyValue === a.keyValue))
  newColumns = [...storedColumns.map((c) => mergeAttributes(c, initColumns)), ...newColumnsToAdd]
  const newColumnsToDestroy = storedColumns.filter((a) => !initColumns.some((b) => b.keyValue === a.keyValue))
  if (!!newColumnsToDestroy.length) {
    for (const item of newColumnsToDestroy) {
      const index = newColumns.findIndex((a) => a.keyValue === item.keyValue)
      if (index !== -1) newColumns.splice(index, 1)
    }
  }
  return newColumns
}
