import type { TPage, TPages } from "./type"
import { FaUsers, FaBuilding, FaMapLocationDot, FaTruckPlane, FaFileWaveform, FaMoneyCheckDollar, FaCircleQuestion } from "react-icons/fa6"
import {
  FaFunnelDollar,
  FaUserCog,
  FaThList,
  FaCogs,
  FaUsersCog,
  FaTruckMoving,
  FaWarehouse,
  FaTrailer,
  FaCar,
  FaSpellCheck,
  FaSitemap,
  FaServicestack,
  FaIdCardAlt,
  FaSearchLocation,
  FaDoorOpen,
} from "react-icons/fa"
import { TbTargetArrow, TbTablePlus, TbHandClick } from "react-icons/tb"
import { MdOutlineHttp, MdMessage } from "react-icons/md"

export const STATIC_PAGES = {
  MAIN: {
    name: "Sign In",
    path: "/sign-in",
  },
  SIGN_IN: {
    name: "Sign In",
    path: "/sign-in",
  },
  SIGN_UP: {
    name: "Sign Up",
    path: "/sign-up",
  },
  FORGET_PASSWORD: {
    name: "Forget Password",
    path: "/forget-password",
  },
  NOT_FOUND: {
    path: "/404",
  },
}

// ID = URL DE API DE MENU
// PATH = URL LOCAL DEL FRONT
export const PAGES: Record<TPages, TPage> = {
  // 👉 Super User Admin
  USERS_ADMINS: {
    id: "administrate_user_admins",
    name: "Users Admins",
    desc: "Administrate your users admins",
    path: "/users-admins",
    Icon: FaUsersCog,
  },
  STATUS: {
    id: "status",
    name: "Status",
    desc: "Manage general status",
    path: "/status",
    Icon: FaSpellCheck,
  },
  FORM_MASTERS: {
    id: "form-masters",
    name: "form masters",
    desc: "Administrate your form masters",
    path: "/form-masters",
    Icon: FaFileWaveform,
  },
  FORM_FIELD_GROUP: {
    id: "form-field-group",
    name: "form field group",
    desc: "Administrate your form field groups",
    path: "/form-field-group",
    Icon: FaFileWaveform,
  },
  FORM_ACTION: {
    id: "form-action",
    name: "form actions",
    desc: "Administrate your form actions",
    path: "/form-action",
    Icon: FaFileWaveform,
  },
  FORM_BUTTON_TYPE: {
    id: "form-button-type",
    name: "form button type",
    desc: "Administrate your form button types",
    path: "/form-button-type",
    Icon: FaFileWaveform,
  },
  FORM_USER_DATATYPE_DEFINER: {
    id: "user-datatype-definer",
    name: "user datatype definer",
    desc: "Administrate your user datatype definers",
    path: "/user-datatype-definer",
    Icon: FaFileWaveform,
  },
  FORM_MASTER_TYPES: {
    id: "form-master-types",
    name: "form master types",
    desc: "Administrate your form master types",
    path: "/form-master-types",
    Icon: FaFileWaveform,
  },
  CRUD_TABLES: {
    id: "crud-tables",
    name: "crud tables",
    desc: "Administrate your Crud Tables",
    path: "/crud-tables",
    Icon: TbTablePlus,
  },
  CRUD_ACTIONS: {
    id: "crud-actions",
    name: "crud actions",
    desc: "Administrate your Crud Actions",
    path: "/crud-actions",
    Icon: TbHandClick,
  },
  // 👉 User Admin
  USERS: {
    id: "users",
    name: "Users",
    desc: "Administrate your users",
    path: "/users",
    Icon: FaUsersCog,
  },
  CLIENTS: {
    id: "clients",
    name: "Clients",
    desc: "Administrate your clients",
    path: "/clients",
    Icon: FaUsers,
  },
  COMPANIES: {
    id: "companies",
    name: "Companies",
    desc: "Administrate your companies",
    path: "/companies",
    Icon: FaBuilding,
  },
  ROLES: {
    id: "roles",
    name: "Roles",
    desc: "Administrate your roles",
    path: "/roles",
    Icon: FaUserCog,
  },
  LOADS: {
    id: "load_list",
    name: "Loads",
    desc: "Administrate your loads",
    path: "/load_list",
    Icon: FaThList,
  },
  DRIVERS: {
    id: "drivers",
    name: "drivers",
    desc: "Administrate your drivers",
    path: "/drivers",
    Icon: FaCar,
  },
  DRIVER_RATES: {
    id: "driver-rates",
    name: "driver rates",
    desc: "Administrate your driver rates",
    path: "/driver-rates",
    Icon: FaMoneyCheckDollar,
  },
  TRAILERS: {
    id: "trailer",
    name: "Trailers",
    desc: "Administrate your trailers",
    path: "/trailer",
    Icon: FaTrailer,
  },
  TRUCKS: {
    id: "trucks",
    name: "Trucks",
    desc: "Administrate your trucks",
    path: "/trucks",
    Icon: FaTruckMoving,
  },
  MISCELLANEOUS: {
    id: "miscs",
    name: "Miscellaneous",
    desc: "Administrate your miscellaneous",
    path: "/miscellaneous",
    Icon: FaUsers,
  },
  TERMINALS: {
    id: "terminals",
    name: "Terminals",
    desc: "Administrate your terminals",
    path: "/terminals",
    Icon: FaWarehouse,
  },
  EQ_CLASSES: {
    id: "eqclasses",
    name: "EQClasses",
    desc: "Administrate your EQClasses",
    path: "/eqclasses",
    Icon: FaSitemap,
  },
  ZONES: {
    id: "zones",
    name: "Zones",
    desc: "Administrate your Zones",
    path: "/zones",
    Icon: FaMapLocationDot,
  },
  CARRIERS: {
    id: "carriers",
    name: "Carriers",
    desc: "Administrate your Carriers",
    path: "/carriers",
    Icon: FaIdCardAlt,
  },
  MENU_OPTIONS: {
    id: "manage_menu_options",
    name: "Menu Options",
    desc: "Administrate your menu options",
    path: "/manage_menu_options",
    Icon: FaCogs,
  },
  COMMODITIES: {
    id: "commodities",
    name: "Commodities",
    desc: "Administrate your Commodities",
    path: "/commodities",
    Icon: FaFunnelDollar,
  },
  SERVICE_LEVEL: {
    id: "service-level",
    name: "Service Level",
    desc: "Administrate your Service Level",
    path: "/service-level",
    Icon: FaServicestack,
  },
  TYPE_ACCHARGES: {
    id: "type-accharges",
    name: "Type Accharges",
    desc: "Administrate your Type Accharges",
    path: "/type-accharges",
    Icon: TbTargetArrow,
  },
  TYPE_TRACES: {
    id: "type-traces",
    name: "Type Traces",
    desc: "Administrate your Type Traces",
    path: "/type-traces",
    Icon: FaSearchLocation,
  },
  DISPATCH: {
    id: "dispatch",
    name: "Dispatch",
    desc: "Administrate your Dispatch",
    path: "/dispatch",
    Icon: FaTruckPlane,
  },
  DOORS: {
    id: "doors",
    name: "Doors",
    desc: "Administrate your Doors",
    path: "/doors",
    Icon: FaDoorOpen,
  },
  TRIPS: {
    id: "trips",
    name: "Trips",
    desc: "Administrate your Trips",
    path: "/trips",
    Icon: FaDoorOpen,
  },
  API_FIELD_OVERRIDE: {
    id: "api-field-overrides",
    name: "Api Field Override",
    desc: "Administrate your Api Field Override",
    path: "/api-field-overrides",
    Icon: MdOutlineHttp,
  },
  MESSAGE_CENTER: {
    id: "message-center",
    name: "center messages",
    desc: "Administrate your center messages",
    path: "/message-center",
    Icon: MdMessage,
  },
  SURVEYS: {
    id: "survey",
    name: "surveys",
    desc: "Administrate your surveys",
    path: "/survey",
    Icon: FaCircleQuestion,
  },
  MENU_OPTIONS_PARENTS: {
    id: "menu-options-parents",
    name: "menu option parents",
    desc: "Administrate your menu options parents",
    path: "/menu-options-parents",
    Icon: FaCogs,
  },
  INSPECTIONS: {
    id: "inspections",
    path: "/inspections",
  },
  DISPATCH_MAP: {
    id: "dispatch-map",
    path: "/dispatch-map",
  },
  CLOCK_IN_FOR_ALL: {
    id: "clock-in-for-all",
    path: "/clock-in-for-all",
  },
  LTL_MILES: {
    id: "ltl-miles",
    path: "/ltl-miles",
  },
  DRIVER_PAY: {
    id: "driver-pay",
    path: "/driver-pay",
  },
}

export enum NAME_PAGES {
  USERS_ADMINS = "USERS_ADMINS",
  STATUS = "STATUS",
  USERS = "USERS",
  CLIENTS = "CLIENTS",
  COMPANIES = "COMPANIES",
  ROLES = "ROLES",
  LOADS = "LOADS",
  FORM_LOAD = "FORM_LOAD",
  DRIVERS = "DRIVERS",
  TRAILERS = "TRAILERS",
  TRUCKS = "TRUCKS",
  MISCELLANEOUS = "MISCELLANEOUS",
  TERMINALS = "TERMINALS",
  EQ_CLASSES = "EQ_CLASSES",
  ZONES = "ZONES",
  CARRIERS = "CARRIERS",
  MENU_OPTIONS = "MENU_OPTIONS",
  COMMODITIES = "COMMODITIES",
  SERVICE_LEVEL = "SERVICE_LEVEL",
  TYPE_ACCHARGES = "TYPE_ACCHARGES",
  TYPE_TRACES = "TYPE_TRACES",
  DISPATCH = "DISPATCH",
  DOORS = "DOORS",
  TRIPS = "TRIPS",
  API_FIELD_OVERRIDE = "API_FIELD_OVERRIDE",
  MESSAGE_CENTER = "MESSAGE_CENTER",
  CRUD_TABLES = "CRUD_TABLES",
  CRUD_ACTIONS = "CRUD_ACTIONS",
  FORM_MASTERS = "FORM_MASTERS",
  FORM_MASTER_TYPES = "FORM_MASTER_TYPES",
  FORM_FIELD_GROUP = "FORM_FIELD_GROUP",
  FORM_ACTION = "FORM_ACTION",
  FORM_BUTTON_TYPE = "FORM_BUTTON_TYPE",
  FORM_USER_DATATYPE_DEFINER = "FORM_USER_DATATYPE_DEFINER",
  SURVEYS = "SURVEYS",
  INSPECTIONS = "INSPECTIONS",
  DISPATCH_MAP = "DISPATCH_MAP",
  CLOCK_IN_FOR_ALL = "CLOCK_IN_FOR_ALL",
  LTL_MILES = "LTL_MILES",
  DRIVER_PAY = "DRIVER_PAY",
}
