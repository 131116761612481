import useEventListener from "./useEventListener"

export default function useClickOutside(ref: any, cb: any) {
  const _doc = document.documentElement || document.body
  useEventListener(
    "click",
    (e) => {
      if (ref.current == null || ref.current.contains(e.target)) return
      cb(e)
    },
    _doc,
  )
}
