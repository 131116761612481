import type { RouteObject } from "react-router-dom"
import { useRoutes } from "react-router-dom"
import { useContext } from "react"
import { cloneDeep } from "lodash"
import { LoginRoutes, AppMainRoutes, getAppRoute } from "@/router"
import { AuthenticationContext } from "@/context/AuthenticateContext"
import { KEY_PATHS, KEY_TOKEN } from "./constants"

export const App = () => {
  const { isLoggedIn, routesWithPermissionsCreated } = useContext(AuthenticationContext)!
  const routes = [...LoginRoutes]
  if (isLoggedIn) {
    const pathsFiltered = routesWithPermissionsCreated.current.filter((c) => c.can.includes("LIST") && c.can.includes("SHOW")).map((c) => c.path)
    const authRouters: RouteObject = cloneDeep(AppMainRoutes)

    const routesFiltered: RouteObject[] = []

    routesWithPermissionsCreated.current.forEach((route) => {
      const currRoute = getAppRoute(route.path)
      if (currRoute) routesFiltered.push(currRoute)
    })

    const newMenu: string[] = []
    for (const _path of pathsFiltered) {
      const index = routesFiltered.findIndex((c) => c.path?.endsWith(_path))
      if (index !== -1) {
        const route = routesFiltered[index]
        if (authRouters.children) {
          newMenu.push(route.path!)
          authRouters.children.push(route)
          if (route.children) authRouters.children.push(...route.children)
        }
      }
    }
    // 👉 You must add the route after registering it - Remove all routes
    const temp: RouteObject[] = []

    if (!newMenu.length) {
      localStorage.removeItem("user")
      localStorage.removeItem(KEY_TOKEN)
    } else {
      if (!!temp.length) temp.forEach((item) => newMenu.push(item.path!))
      localStorage.setItem(KEY_PATHS, JSON.stringify(newMenu))
    }

    if (!!temp.length) temp.forEach((item) => authRouters.children!.push(item))
    routes.push(authRouters)
  }
  const elements = useRoutes(routes)
  return elements
}

export function findRoute(routeList: RouteObject[], path: string): RouteObject {
  return routeList.find((c) => c.path === path)!
}
