import { useEffect, useRef } from "react"

type Callback = (event: Event) => void

export default function useEventListener(eventType: string, callback: Callback, element: Window | HTMLElement = window) {
  const callbackRef = useRef<Callback>(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    if (element == null) return
    const handler = (e: Event) => callbackRef.current(e)
    element.addEventListener(eventType, handler)

    return () => element.removeEventListener(eventType, handler)
  }, [eventType, element])
}
