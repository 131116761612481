import * as Listeners from "@/context/SocketContext"
import * as ListenersApp from "@/context/AppContext"

let socketIns: any = null

interface RegisterServiceWorkerParams {
  socket: any
}

export const registerServiceWorker = async ({ socket }: RegisterServiceWorkerParams) => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register("/sw.js", {
        type: "classic",
        updateViaCache: "imports",
        scope: "/src/serviceWorker/",
      })
      await registration.update()
      socketIns = socket
      handleSocketEvents(socket)
    } catch (error) {}
  }
}

export const unregisterServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registrations = await navigator.serviceWorker.getRegistrations()
      registrations.forEach(async (registration) => {
        if (registration) {
          await registration.unregister()
        }
      })
      socketIns?.off()
      socketIns?.disconnect()
      socketIns = null
    } catch (error) {
      console.error("Error al dar de baja Service Worker:", error)
    }
  }
}

const handleSocketEvents = (socket = socketIns) => {
  socket?.on("getUsersConnected", (values: any) => {
    // console.log("getUsersConnected", values)
    Listeners.onGetUsersConnected(values)
  })
  socket?.on("chat", (values: any) => {
    // console.log("chat", values)
    Listeners.onChat(values)
  })
  socket?.on("update-message", (values: any) => {
    // console.log("update-message", values)
    Listeners.onUpdateMessage(values)
  })
  socket?.on("request-available-rooms", (values: any) => {
    // console.log("request-available-rooms", values)
    Listeners.onRequestAvailableRooms(values)
  })
  socket?.on("destroy-message", (values: any) => {
    // console.log("destroy-message", values)
    Listeners.onDestroyMessage(values)
  })
  socket?.on("readMessage", (values: any) => {
    // console.log("readMessage", values)
    Listeners.onReadMessage(values)
  })
  socket?.on("sync-session", (values: any) => {
    // console.log("sync-session", values)
    Listeners.onSyncSession(values)
  })
  socket?.on("history-messages", (values: any) => {
    // console.log("history-messages", values)
    Listeners.onHistoryMessages(values)
  })
  socket?.on("receive-global-message", (values: any) => {
    // console.log("receive-global-message", values)
    Listeners.onReceiveGlobalMessage(values)
  })
  socket?.on("create-freight", (values: any) => {
    // console.log("create-freight", values)
    ListenersApp.createFreightHandler(values)
  })
  socket?.on("update-freight", (values: any) => {
    // console.log("update-freight", values)
    ListenersApp.updateFreightHandler(values)
  })
  socket?.on("destroy-freight", (values: any) => {
    // console.log("destroy-freight", values)
    ListenersApp.destroyFreightHandler(values)
  })
  socket?.on("add-document-freight", (values: any) => {
    // console.log("add-document-freight", values)
    ListenersApp.addDocumentFreightHandler(values)
  })
  socket?.on("assign-or-unassign-to-trip", (values: any) => {
    // console.log("assign-or-unassign-to-trip", values)
    ListenersApp.toggleAssignToTripHandler(values)
  })
  socket?.on("create-trip", (values: any) => {
    // console.log("create-trip", values)
    ListenersApp.createTripHandler(values)
  })
  socket?.on("update-trip", (values: any) => {
    // console.log("update-trip", values)
    ListenersApp.updateTripHandler(values)
  })
  socket?.on("create-door", (values: any) => {
    // console.log("create-door", values)
    ListenersApp.createDoorHandler(values)
  })
  socket?.on("update-door", (values: any) => {
    // console.log("update-door", values)
    ListenersApp.updateDoorHandler(values)
  })
  socket?.on("destroy-door", (values: any) => {
    // console.log("destroy-door", values)
    ListenersApp.destroyDoorHandler(values)
  })
  socket?.on("get-visited-pages", (values: any) => {
    // console.log("get-visited-pages", values)
    ListenersApp.getPageHistory(values)
  })
  socket?.on("disconnect", () => {
    console.info("Disconnected 🖐")
  })
}
