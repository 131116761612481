import React, { createContext, useState, Dispatch, SetStateAction, useEffect } from "react"

type Theme = "light" | "dark"

interface TAppContext {
  collapsedSidebar: boolean
  toggleSidebar: boolean
  isMobile: boolean
  setCollapsedSidebar: Dispatch<SetStateAction<boolean>>
  setToggleSidebar: Dispatch<SetStateAction<boolean>>
  setCollapsed(): void
  // 👉 New Sidebar Prop
  _setCollapsed: Dispatch<SetStateAction<boolean>>
  setToggled: Dispatch<SetStateAction<boolean>>
  setBroken: Dispatch<SetStateAction<boolean>>
  collapsed: boolean
  toggled: boolean
  broken: boolean
  rtl: boolean
  hasImage: boolean
  theme: Theme
  collapseMenu(): void
}

export const LayoutContext = createContext<TAppContext | null>(null)
const LayoutContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const [collapsedSidebar, setCollapsedSidebar] = useState<boolean>(false)
  const [toggleSidebar, setToggleSidebar] = useState<boolean>(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480)

  const [collapsed, _setCollapsed] = useState<boolean>(false)
  const [toggled, setToggled] = useState<boolean>(false)
  const [broken, setBroken] = useState<boolean>(false)
  const [rtl] = useState<boolean>(false)
  const [hasImage] = useState<boolean>(false)
  const [theme] = React.useState<Theme>("light")

  useEffect(() => {
    const handleResize = () => {
      const _isMobile = window.innerWidth <= 480
      setIsMobile(_isMobile)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const setCollapsed = () => {
    setCollapsedSidebar(true)
  }

  const collapseMenu = () => {
    if (broken) {
      setToggled(true)
      _setCollapsed(false)
    } else {
      _setCollapsed(true)
    }
  }

  const value = {
    setCollapsedSidebar,
    collapsedSidebar,
    toggleSidebar,
    setToggleSidebar,
    isMobile,
    setCollapsed,
    // 👉 New Sidebar Prop
    _setCollapsed,
    setToggled,
    setBroken,
    collapsed,
    toggled,
    broken,
    rtl,
    hasImage,
    theme,
    collapseMenu,
  }
  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export default LayoutContextProvider
