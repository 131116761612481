interface SidebarHeaderProps {
  collapsed: boolean
}

export const SidebarHeader = ({ collapsed }: SidebarHeaderProps) => {
  return (
    <div className="py-3 px-4 flex items-center">
      <figure>
        <img src="/images/logo.png" className="h-8" alt="Logo" />
      </figure>
      {!collapsed && <h1 className="font-bold text-xl">T4 TMS</h1>}
    </div>
  )
}
