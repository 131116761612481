import { ChangeEvent, useCallback, useState } from "react"
import { ResponseListStatus, Form, TStatus } from "../StatusType"
import axios from "@axios"
import { AxiosResponse } from "axios"
import { debounce } from "lodash"
import { DEBOUNCE_START_DELAY } from "@/constants"

export const useStatus = (filter = {}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [status, setStatus] = useState<TStatus[]>([])
  const [pagination, setPagination] = useState<Partial<ResponseListStatus["meta"]>>({})
  const [filters, setFilters] = useState<Partial<TStatus>>({})

  const onChangeFilters =
    <K extends keyof TStatus>(prop: K) =>
    (ev: ChangeEvent<HTMLInputElement>) => {
      const values = {
        ...filters,
        [prop]: !!ev.target.value ? ev.target.value : undefined,
      }
      setFilters(values)
    }

  const fetchAll = useCallback(
    async (page: number = 1): Promise<TStatus[] | void> => {
      if (loading) return []
      try {
        setLoading(true)
        const params = {
          ...filters,
          per_page: 22,
          page,
          ...filter,
        }
        const { data: res } = await axios.get<ResponseListStatus>(`status`, { params })
        const { data, meta } = res
        if (status) {
          setPagination(meta)
          setStatus(data)
          return data
        } else {
          throw new Error("Error fetch status")
        }
      } catch (error) {
        return []
      } finally {
        setLoading(false)
      }
    },
    [loading, filters],
  )

  const debounceFetchAll = debounce(fetchAll, DEBOUNCE_START_DELAY)

  const store = async (payload: Form): Promise<AxiosResponse<TStatus>> => {
    return await axios.post<TStatus>(`status`, payload)
  }

  const update = async (recordId: number, payload: Form): Promise<AxiosResponse<TStatus>> => {
    return await axios.put<TStatus>(`status/${recordId}`, payload)
  }

  const destroy = async (recordId: number) => {
    try {
      setLoading(true)
      await axios.delete<ResponseDelete>(`status/${recordId}`)
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    status,
    pagination,
    fetchAll,
    store,
    update,
    destroy,
    // 👉 Filters
    debounceFetchAll,
    onChangeFilters,
    filters,
  }
}
