const FormLogin = {
  email: "",
  password: "",
  rememberMe: false,
}

const FormRegister = {
  name: "",
  email: "",
  password: "",
  company_name: "",
  company_email: "",
  company_address: "",
  company_state: "",
  company_city: "",
  company_zip_code: "",
  company_country: "",
}

export default {
  FormLogin,
  FormRegister,
}
