import { createPortal } from "react-dom"
import { FaX } from "react-icons/fa6"

interface Props {
  uri: string | undefined
  onClose?(): void
}

const ChatPreviewPdf = ({ uri, onClose }: Props) => {
  if (!uri) return null
  return createPortal(
    <div className="fixed overflow-hidden z-[150] inset-0" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 sm:block sm:p-0 text-center">
        <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"></span>
        <div className={"inline-block w-10/12 relative align-bottom bg-[#525659] rounded-md shadow-xl transform transition-all sm:align-middle mx-auto"}>
          <button className="absolute -right-4 -top-4 bg-[#323639] border-2 border-white rounded-full p-3 text-white" onClick={() => onClose && onClose()}>
            <FaX className="text-xs" />
          </button>
          <div className="text-start rounded-md">
            <div className="sm:mt-0 rounded-md">
              <div className="bg-transparent">
                <embed src={uri} type="application/pdf" style={{ width: "100%", height: "85vh" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("preview-pdf")!,
  )
}

export default ChatPreviewPdf
