import { FaCirclePlus, FaTruckArrowRight, FaTrailer, FaUserPlus, FaPenToSquare, FaTrashCan } from "react-icons/fa6"
import { useNavigate } from "react-router-dom"
import { MouseEvent, useCallback, useContext, useEffect, useRef, useState } from "react"
import Swal from "sweetalert2"
import { FaFolderOpen, FaColumns, FaSyncAlt, FaLayerGroup } from "react-icons/fa"
import { TbPinnedFilled } from "react-icons/tb"
import { toast } from "react-toastify"
import { useIntl } from "react-intl"
import { cloneDeep, debounce } from "lodash"
import { MdDragIndicator } from "react-icons/md"
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import { format } from "date-fns"
import clsx from "clsx"

import { Button, Input, Modal, Pagination, Title, ArrowSort, Select, Dropdown } from "@core/index"
import { AuthenticationContext } from "@/context/AuthenticateContext"
import { NAME_PAGES, PAGES } from "@/router/routeInitial.state"
import useDocumentTitle from "@/hooks/useDocumentTitle"
import MenuContext, { ContextMenuProps, IForwardedRef } from "@/@core/MenuContext"

import { useLoads } from "./hooks/useLoads"
import { CONFIG_MAIN_TOAST, PER_PAGES, SwalConfig } from "@/constants"
import { LayoutContext } from "@/context/AppAuthContext"
import type { Load, IFormUpdateStatus, IModals } from "./LoadType"
import { DispatchEventsMenuContext } from "./load.enum"
import { LoadFormUpdateStatus } from "./components/LoadFormUpdateStatus"
import { LoadContext } from "./context/LoadContext"
import { PreviewFile } from "./components/PreviewFile"
import { AssignDriver } from "./components/AssignDriver"
import { AssignTruck } from "./components/AssignTruck"
import { AssignTrailer } from "./components/AssignTrailer"
import HideShowColumns from "@/@core/HideShowColumns/HideShowColumns"
import { TTableFormLoad } from "./Load.columns"
import TableResizable, { ResizableTD, ResizableTH, ResizableTR } from "@/@core/TableResizable"
import TableSkeleton from "@/@core/TableSkeleton"
import { ColumnContext } from "@/context/ColumnContext"
import { ILoadToTable } from "../DispatchPage/DispatchType"
import { AppContext } from "@/context/AppContext"
import { SocketContext } from "@/context/SocketContext"
import { Adapter } from "./LoadAdapter"
import { hasChanged } from "@/utils"
import useDebounce from "@/hooks/useDebounce"

const CURRENT_NAME_PAGE = "LOADS"
const MAX_BULK_SELECTED_ITEMS = 5
const PATH: string = PAGES.LOADS.id
const LoadsPage = (): JSX.Element => {
  const { storeLoads, dispatchLoads, dispatchApp, dispatchDispatch, storeApp } = useContext(AppContext)
  const { freightFilters, prevFreightFilters, freights, pagination } = storeLoads

  const { formatMessage: $t } = useIntl()
  const navigate = useNavigate()
  const { setCurrentLoad } = useContext(LoadContext)
  const { emitEvent, isConnected } = useContext(SocketContext)
  const { Can, can, CanSome } = useContext(AuthenticationContext)!
  const { collapseMenu } = useContext(LayoutContext)!
  const [openModal, setOpenModal] = useState<IModals>()
  const { fetchAll, destroy, loading, setLoads } = useLoads(freightFilters)
  const [showForm, setShowForm] = useState<boolean>(false)
  const [currDataFormStatus, setCurrDataFormStatus] = useState<IFormUpdateStatus>()
  const [processingLoad, setProcessingLoad] = useState<Load>({} as Load)
  const [showModalUpdateColumn, setShowModalUpdateColumn] = useState<boolean>(false)
  const [listColumnsToUpdate, setListColumnsToUpdate] = useState<Column[]>([])
  const { columnsLoads, setColumnsLoads, handleSortColumnTableChange, loadColumns } = useContext(ColumnContext)
  const [canSelectFreights, setCanSelectFreights] = useState<boolean>(false)
  const [bulkPinFreights, setBulkPinFreights] = useState<ILoadToTable[]>([])
  const menuContextRef = useRef<IForwardedRef>(null)
  const [coords, setCoords] = useState({})

  const handleChangePage = (page: number) => {
    dispatchLoads({ type: "UPDATE_FILTERS", payload: { page } })
  }

  const loadFreights = useCallback(() => {
    setBulkPinFreights([])
    fetchAll().then(({ freights, pagination }) => {
      dispatchLoads({ type: "LOAD_FREIGHTS", payload: freights })
      dispatchLoads({ type: "UPDATE_PAGINATION", payload: pagination })
    })
  }, [prevFreightFilters, freightFilters])

  const handleContextMenu = (ev: MouseEvent<HTMLTableSectionElement> | undefined) => {
    if (!ev) return
    ev.preventDefault()
    ev.stopPropagation()
    const { clientX, clientY } = ev
    setCoords({ left: `${clientX}px`, top: `${clientY}px` })
    if (menuContextRef.current) menuContextRef.current.openMenuContext()
  }

  useEffect(() => {
    if (hasChanged({ prev: prevFreightFilters, current: freightFilters })) {
      dispatchLoads({ type: "UPDATE_PREV_FILTERS", payload: freightFilters })
      loadFreights()
    }
  }, [freightFilters])

  useDebounce(() => loadColumns("LOADS"), 350, [])

  useEffect(() => {
    const collapseMenuDebounce = debounce(() => collapseMenu(), 350)
    collapseMenuDebounce()
  }, [])

  useDocumentTitle($t({ id: "Loads" }))

  const handleDestroyClick = async (load: ILoadToTable) => {
    try {
      const { isConfirmed } = await Swal.fire(SwalConfig.questionDestroy({ trans: $t }))
      if (isConfirmed) {
        await destroy(load.id)
        if (isConnected) {
          emitEvent("destroy-freight", load)
          const pathname = `${PAGES.LOADS.path}/edit/${load.id}`
          if (storeApp.visitedPages.some((vp) => vp.pathname == pathname)) {
            dispatchApp({ type: "REMOVE_PAGE_OF_VISITED_PAGES", payload: { pathname } })
          }
          dispatchLoads({ type: "REMOVE_FREIGHT_TO_LIST", payload: load })
          dispatchDispatch({
            type: "TOGGLE_ADD_TO_FREIGHTS",
            payload: {
              newItem: Adapter.loadToLoadWithType(load, "D"),
              freightId: load.id,
              type: "Delivery",
              action: "REMOVE",
            },
          })
          dispatchDispatch({
            type: "TOGGLE_ADD_TO_FREIGHTS",
            payload: {
              newItem: Adapter.loadToLoadWithType(load, "P"),
              freightId: load.id,
              type: "Pickup",
              action: "REMOVE",
            },
          })
        } else {
          fetchAll()
        }
        toast.success($t({ id: "your record text has been deleted" }, { text: load.bill_number }), CONFIG_MAIN_TOAST)
      }
    } catch (err) {}
  }

  const closeModal = () => {
    setShowForm(false)
    setCurrDataFormStatus(undefined)
  }

  const handleSuccess = (loadUpdated: Load) => {
    closeModal()
    if (!isConnected) {
      fetchAll()
    }
    setLoads((prev) => {
      const newState = [...prev]
      const index = newState.findIndex((c) => c.id == loadUpdated.id)
      if (index != -1) newState.splice(index, 1, loadUpdated)
      return newState
    })
    toast.success($t({ id: "Successfully Updated" }, { input: loadUpdated.bill_number }), CONFIG_MAIN_TOAST)
  }

  const openModalUpdateStatus = (load: Load) => {
    setCurrDataFormStatus({
      freight_id: load.id,
      comments: "",
      status_id: 0,
      date_time_status: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
    })
    setCurrentLoad(load)
    setShowForm(true)
  }

  // 👉 Document Files
  const [renderModal, setRenderModal] = useState<string>("")
  const openRenderModal = (item: Load, newModal: string) => {
    setCurrentLoad(item)
    setProcessingLoad(item)
    setRenderModal(newModal)
  }

  const closeRenderModal = () => {
    setRenderModal("")
    setCurrentLoad({} as Load)
  }

  const assignDriverInterceptor = () => {
    closeRenderModal()
    loadFreights()
  }

  const assignTruckInterceptor = () => {
    closeRenderModal()
    loadFreights()
  }

  const assignTrailerInterceptor = () => {
    closeRenderModal()
    loadFreights()
  }

  const renderDrivers = (currentLoad: Load): JSX.Element => {
    const [_pickups, _deliveries] = currentLoad.trip_info.map((c) => c.drivers)
    return (
      <>
        {_pickups?.length
          ? _pickups.map((c, index) => (
              <span key={index}>
                {can({ path: PATH, can: "UPDATE" }) ? (
                  <Button icon color="primary" onClick={() => openRenderModal(currentLoad, "ASSIGN_DRIVER")}>
                    <small className="text-[10px] block w-[60px] text-ellipsis overflow-hidden text-nowrap">{c.name}</small>
                  </Button>
                ) : (
                  <small className="text-[10px] block w-[60px] text-ellipsis overflow-hidden text-nowrap">{c.name}</small>
                )}
              </span>
            ))
          : ""}
        <span> / </span>
        {_deliveries?.length
          ? _deliveries.map((c, index) => (
              <span key={index}>
                {can({ path: PATH, can: "UPDATE" }) ? (
                  <Button icon color="success" onClick={() => openRenderModal(currentLoad, "ASSIGN_DRIVER")}>
                    <small className="text-[10px] block w-[60px] text-ellipsis overflow-hidden text-nowrap">{c.name}</small>
                  </Button>
                ) : (
                  <small className="text-[10px] block w-[60px] text-ellipsis overflow-hidden text-nowrap">{c.name}</small>
                )}
              </span>
            ))
          : ""}
      </>
    )
  }

  const renderTrucks = (currentLoad: Load): JSX.Element => {
    const [_pickups, _deliveries] = currentLoad.trip_info.map((c) => c.trucks)
    return (
      <>
        {_pickups?.length
          ? _pickups.map((c, index) => (
              <span key={index}>
                {can({ path: PATH, can: "UPDATE" }) ? (
                  <Button icon color="primary" onClick={() => openRenderModal(currentLoad, "ASSIGN_TRUCK")}>
                    <span className="text-xs block w-[60px] text-ellipsis overflow-hidden text-nowrap">{c.name}</span>
                  </Button>
                ) : (
                  <span className="text-xs block w-[60px] text-ellipsis overflow-hidden text-nowrap">{c.name}</span>
                )}
              </span>
            ))
          : ""}
        <span> / </span>
        {_deliveries?.length
          ? _deliveries.map((c, index) => (
              <span key={index}>
                {can({ path: PATH, can: "UPDATE" }) ? (
                  <Button icon color="success" onClick={() => openRenderModal(currentLoad, "ASSIGN_TRUCK")}>
                    <span className="text-xs block w-[60px] text-ellipsis overflow-hidden text-nowrap">{c.name}</span>
                  </Button>
                ) : (
                  <span className="text-xs block w-[60px] text-ellipsis overflow-hidden text-nowrap">{c.name}</span>
                )}
              </span>
            ))
          : ""}
      </>
    )
  }

  const renderTrailers = (currentLoad: Load): JSX.Element => {
    const [_pickups, _deliveries] = currentLoad.trip_info.map((c) => c.trailers)
    return (
      <>
        {_pickups?.length
          ? _pickups.map((c, index) => (
              <span key={index}>
                {can({ path: PATH, can: "UPDATE" }) ? (
                  <Button icon color="primary" onClick={() => openRenderModal(currentLoad, "ASSIGN_TRAILER")}>
                    <span className="text-xs block w-[60px] text-ellipsis overflow-hidden text-nowrap">{c.name}</span>
                  </Button>
                ) : (
                  <span className="text-xs block w-[60px] text-ellipsis overflow-hidden text-nowrap">{c.name}</span>
                )}
              </span>
            ))
          : ""}
        <span> / </span>
        {_deliveries?.length
          ? _deliveries.map((c, index) => (
              <span key={index}>
                {can({ path: PATH, can: "UPDATE" }) ? (
                  <Button icon color="success" onClick={() => openRenderModal(currentLoad, "ASSIGN_TRAILER")}>
                    <span className="text-xs block w-[60px] text-ellipsis overflow-hidden text-nowrap">{c.name}</span>
                  </Button>
                ) : (
                  <span className="text-xs block w-[60px] text-ellipsis overflow-hidden text-nowrap">{c.name}</span>
                )}
              </span>
            ))
          : ""}
      </>
    )
  }

  const handlerOnChange = (newValue: EmitValuesSort) => {
    const payload = {
      order_by: newValue.field,
      asc_or_desc: newValue.sort,
    }
    dispatchLoads({ type: "UPDATE_FILTERS", payload })
  }

  const handlerUpdateColumns = (updatedColumns: Column[]) => {
    handleSortColumnTableChange(TTableFormLoad.MAIN, updatedColumns, CURRENT_NAME_PAGE)
    setColumnsLoads(updatedColumns)
    setShowModalUpdateColumn(false)
  }

  const reorder = <T extends unknown>(list: T[], startIndex: number, endIndex: number): T[] => {
    const result = cloneDeep(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = async (result: DropResult) => {
    if (result.source.droppableId !== result.destination?.droppableId) return
    setColumnsLoads((prev) => {
      const ordered = reorder(prev, result.source.index, result.destination!.index)
      handleSortColumnTableChange(TTableFormLoad.MAIN, ordered, CURRENT_NAME_PAGE)
      return ordered
    })
  }

  const onUpdateSizeChildColumns = (values: any) => {
    const newColumnsToUpdate = columnsLoads.map((column) => ({
      ...column,
      inlineSize: values[column.keySize!] ?? column.inlineSize,
    }))
    handleSortColumnTableChange(TTableFormLoad.MAIN, newColumnsToUpdate, NAME_PAGES.LOADS)
    setColumnsLoads(newColumnsToUpdate)
  }

  const onSelectLoad = ({ open, load }: { open: boolean; load?: Load }) => {
    if (open) {
      dispatchLoads({ type: "SET_FREIGHT_SELECTED", payload: load })
      setOpenModal("VIEW_DOCS")
    } else {
      setOpenModal(undefined)
      dispatchLoads({ type: "SET_FREIGHT_SELECTED", payload: undefined })
    }
  }

  const handlers = { onDragEnd }

  useEffect(() => {
    if (!canSelectFreights) {
      setBulkPinFreights([])
    }
  }, [canSelectFreights])

  const togglePin = (data: ILoadToTable) => {
    const indexInList = bulkPinFreights.findIndex((c) => c.id == data.id)
    if (indexInList !== -1) {
      setBulkPinFreights((prev) => {
        const newGroup = [...prev]
        newGroup.splice(indexInList, 1)
        return newGroup
      })
    } else if (bulkPinFreights.length < MAX_BULK_SELECTED_ITEMS) {
      setBulkPinFreights((prev) => [...prev, data])
    }
  }

  const pinRecentlyVisitedPagesHandleClick = () => {
    bulkPinFreights.forEach((freight) => {
      dispatchApp({
        type: "ADD_PAGE_TO_VISITED_PAGES",
        payload: { title: `Load #${freight.bill_number}`, pathname: `${PAGES.LOADS.path}/edit/${freight.id}` },
      })
    })
    setCanSelectFreights(false)
  }

  const loadsFiltered = freights.map(Adapter.LoadToTable)
  const freightProcessing = useRef<ILoadToTable>()

  const listMenuContext: ContextMenuProps["listItems"] = [
    {
      caption: $t({ id: "update columns" }),
      dispatchEvent: DispatchEventsMenuContext.UPDATE_COLUMNS,
      Icon: FaColumns,
    },
  ]

  if (!!freightProcessing.current) {
    listMenuContext.unshift({
      caption: $t({ id: "pin" }),
      dispatchEvent: DispatchEventsMenuContext.PIN,
      Icon: TbPinnedFilled,
    })
  }

  const onActionMenuContext = ({ action }: { action: string }) => {
    switch (action as DispatchEventsMenuContext) {
      case DispatchEventsMenuContext.PIN:
        {
          dispatchApp({
            type: "ADD_PAGE_TO_VISITED_PAGES",
            // @ts-ignore
            payload: { title: `Load #${freightProcessing.current?.bill_number}`, pathname: `${PAGES.LOADS.path}/edit/${freightProcessing.current?.id}` },
          })
        }
        break
      case DispatchEventsMenuContext.UPDATE_COLUMNS:
        {
          setListColumnsToUpdate(cloneDeep(columnsLoads))
          setShowModalUpdateColumn(true)
        }
        break
      default:
        break
    }
  }

  const onSelectDetailClickRight = (ev: MouseEvent<HTMLTableRowElement> | undefined, freight: ILoadToTable) => {
    ev?.preventDefault()
    freightProcessing.current = freight
  }

  const [search, setSearch] = useState<string>(freightFilters?.search || "")
  useDebounce(
    () => {
      dispatchLoads({ type: "UPDATE_FILTERS", payload: { page: 1, search: search.trim() } })
    },
    600,
    [search],
  )

  const columnsToShow = columnsLoads.filter((c) => !c.isHidden)

  return (
    <>
      <MenuContext
        ref={menuContextRef}
        textHeader={`FB ${freightProcessing.current?.bill_number}`}
        onAction={onActionMenuContext}
        listItems={listMenuContext}
        style={coords}
      />
      <Modal
        open={showForm}
        size="xxs"
        title={`${$t({ id: "Edit" })} ${$t({ id: "Status" })}`}
        onClose={closeModal}
        classesWrapper={"px-2 pt-5 pb-2 sm:px-6 sm:py-4"}
      >
        <LoadFormUpdateStatus onCancel={closeModal} initState={currDataFormStatus!} onSuccess={handleSuccess} />
      </Modal>
      <Modal open={openModal === "VIEW_DOCS"} size="xl" title={`${$t({ id: "Files" })}`} onClose={() => onSelectLoad({ open: false })}>
        <div className="px-2 pt-5 pb-2 sm:px-6 sm:py-4">
          <PreviewFile files={storeLoads.freightSelected?.documents || []} />
        </div>
      </Modal>
      <Modal
        open={renderModal === "ASSIGN_DRIVER"}
        size="sm"
        title={<p>{`${$t({ id: "Assign" })} ${$t({ id: "Driver" })} ${String(processingLoad?.bill_number).toUpperCase()}`}</p>}
        onClose={closeRenderModal}
      >
        <div className="p-3">
          <AssignDriver load={processingLoad!} onAssignedDriver={assignDriverInterceptor} onUnassignedDriver={assignDriverInterceptor} />
        </div>
      </Modal>
      <Modal
        open={renderModal === "ASSIGN_TRUCK"}
        size="sm"
        title={<p>{`${$t({ id: "Assign" })} ${$t({ id: "Truck" })} ${String(processingLoad?.bill_number).toUpperCase()}`}</p>}
        onClose={closeRenderModal}
      >
        <div className="p-3">
          <AssignTruck load={processingLoad!} onAssignedTruck={assignTruckInterceptor} onUnassignedTruck={assignTruckInterceptor} />
        </div>
      </Modal>
      <Modal
        open={renderModal === "ASSIGN_TRAILER"}
        size="sm"
        title={<p>{`${$t({ id: "Assign" })} ${$t({ id: "Trailer" })} ${String(processingLoad?.bill_number).toUpperCase()}`}</p>}
        onClose={closeRenderModal}
      >
        <div className="p-3">
          <AssignTrailer load={processingLoad!} onAssignedTrailer={assignTrailerInterceptor} onUnassignedTrailer={assignTrailerInterceptor} />
        </div>
      </Modal>
      <Modal size="xxl" open={showModalUpdateColumn} title={$t({ id: "update columns" })} onClose={() => setShowModalUpdateColumn(false)}>
        <HideShowColumns columns={listColumnsToUpdate} onUpdateColumns={handlerUpdateColumns} onClose={() => setShowModalUpdateColumn(false)} />
      </Modal>
      <div className="mb-2">
        <div className="flex items-start justify-between mb-1">
          <div className="inline-block">
            <Title>
              {$t({ id: "Manage" })} {$t({ id: "Loads" })}
            </Title>
            <p className="text-xs text-slate-400">
              {$t({ id: "there are counter records out of total records" }, { counter: loading ? 0 : pagination.count, total: loading ? 0 : pagination.total })}
            </p>
          </div>
          <Can path={PATH} can={"INSERT"}>
            <Button className="flex gap-2 items-center" color="success" onClick={() => navigate(`${PAGES.LOADS.path}/create`)}>
              <FaCirclePlus className="text-xs" />
              <span className="text-xs">
                {$t({ id: "Add" })} {$t({ id: "Load" })}
              </span>
            </Button>
          </Can>
        </div>
        <div className="flex justify-between">
          <div className="flex gap-1 items-center">
            <Input placeholder={$t({ id: "Search" })} className="w-auto" value={search} onChange={(ev) => setSearch(ev.target.value)} readOnly={loading} />
            <Button
              notify={columnsToShow.length === 0 && !loading}
              tooltip={$t({ id: "update columns" })}
              onClick={() => {
                setListColumnsToUpdate(cloneDeep(columnsLoads))
                setShowModalUpdateColumn(true)
              }}
              className="py-2"
              color="info"
              icon
            >
              <FaColumns className="w-3.5" />
            </Button>
            <Button className="py-2" tooltip={$t({ id: "synchronize" })} onClick={() => loadFreights()} disabled={loading} icon>
              <FaSyncAlt />
            </Button>
            <button
              data-tooltip-id="t4tms-tooltip"
              data-tooltip-content={$t({ id: "bulk assignment" })}
              onClick={() => setCanSelectFreights((prev) => !prev)}
              className={clsx("px-2.5 py-2 border border-slate-700 rounded", canSelectFreights && "bg-slate-700 text-white")}
            >
              <FaLayerGroup className="text-[14px]" />
            </button>
            {!!bulkPinFreights.length && (
              <Button icon color="warning" tooltip={$t({ id: "pin to recently visited pages" })} onClick={pinRecentlyVisitedPagesHandleClick}>
                <TbPinnedFilled className="text-base" />
              </Button>
            )}
          </div>
          <div className="flex items-center gap-1">
            <div className="max-w-max">
              <Select
                className="!pe-5"
                disabled={loading}
                value={freightFilters.per_page}
                onChange={(ev) => dispatchLoads({ type: "UPDATE_FILTERS", payload: { per_page: Number(ev.target.value) } })}
              >
                {PER_PAGES.map((perPage, index) => (
                  <option value={perPage} key={index}>
                    {perPage}
                  </option>
                ))}
              </Select>
            </div>
            <Pagination disabled={loading} currentPage={freightFilters.page} onPageChange={handleChangePage} pages={pagination.total_pages} />
          </div>
        </div>
      </div>
      <DragDropContext {...handlers}>
        <TableResizable onUpdateSizeChildColumns={onUpdateSizeChildColumns} style={{ minBlockSize: "150px" }}>
          <thead>
            <Droppable droppableId={CURRENT_NAME_PAGE} direction="horizontal" type={CURRENT_NAME_PAGE}>
              {(provided) => (
                <tr ref={provided.innerRef} {...provided.droppableProps}>
                  {canSelectFreights && <ResizableTH noResize initialInlineSize={30} ignoreMinSize></ResizableTH>}
                  {columnsToShow.map((item, index) =>
                    item.keyValue === "options" ? (
                      <ResizableTH key={index} keySize={item.keySize} initialInlineSize={Number(item.inlineSize) | 125}>
                        <p className="flex gap-0-.5">
                          <Draggable key={index} draggableId={`${CURRENT_NAME_PAGE}_${index}`} index={index}>
                            {(providedDrag) => (
                              <span className="block" ref={providedDrag.innerRef} {...providedDrag.draggableProps} {...providedDrag.dragHandleProps}>
                                <MdDragIndicator className="text-[16px] text-gray-400" />
                              </span>
                            )}
                          </Draggable>
                          {!!item.sortField ? (
                            <ArrowSort
                              field={item.sortField}
                              filterBy={freightFilters.order_by!}
                              value={freightFilters.asc_or_desc!}
                              handleChange={handlerOnChange}
                            >
                              <span className={"capitalize"}>{item.caption}</span>
                            </ArrowSort>
                          ) : (
                            <span className={"capitalize"}>{item.caption}</span>
                          )}
                          {provided.placeholder}
                        </p>
                      </ResizableTH>
                    ) : (
                      <ResizableTH key={index} keySize={item.keySize} initialInlineSize={Number(item.inlineSize || 125)}>
                        <p className="flex gap-0-.5">
                          <Draggable key={index} draggableId={`${CURRENT_NAME_PAGE}_${index}`} index={index}>
                            {(providedDrag) => (
                              <span className="block" ref={providedDrag.innerRef} {...providedDrag.draggableProps} {...providedDrag.dragHandleProps}>
                                <MdDragIndicator className="text-[16px] text-gray-400" />
                              </span>
                            )}
                          </Draggable>
                          {!!item.sortField ? (
                            <ArrowSort
                              field={item.sortField}
                              filterBy={freightFilters.order_by!}
                              value={freightFilters.asc_or_desc!}
                              handleChange={handlerOnChange}
                            >
                              <span className={"capitalize"}>{item.caption}</span>
                            </ArrowSort>
                          ) : (
                            <span className={"capitalize"}>{item.caption}</span>
                          )}
                          {provided.placeholder}
                        </p>
                      </ResizableTH>
                    ),
                  )}
                </tr>
              )}
            </Droppable>
          </thead>
          <tbody onContextMenu={handleContextMenu}>
            {loading ? (
              <TableSkeleton rows={20} cols={columnsToShow.length} heightSkeleton="h-8" />
            ) : (
              loadsFiltered.map((load, index) => (
                <ResizableTR key={index} hover onContextMenu={(ev) => onSelectDetailClickRight(ev, load)}>
                  {canSelectFreights && (
                    <ResizableTD className="text-center">
                      <input
                        type="checkbox"
                        className="accent-[#172554] scale-110 cursor-pointer"
                        checked={bulkPinFreights.some((c) => c.id == load.id)}
                        onChange={() => togglePin(load)}
                      />
                    </ResizableTD>
                  )}
                  {columnsToShow.map((column, indexColumn) =>
                    column.keyValue === "tripNumbers" ? (
                      <ResizableTD
                        key={indexColumn}
                        className="text-start"
                        style={{ maxInlineSize: `${column.inlineSize}px`, inlineSize: `${column.inlineSize}px` }}
                      >
                        <p className="text-[10px] ps-2">
                          {!!load.trip_info.find((c) => c.tx_type === "P")?.id && (
                            <span className="block">P: {load.trip_info.find((c) => c.tx_type === "P")?.id ?? "-"}</span>
                          )}
                          {!!load.trip_info.find((c) => c.tx_type === "D")?.id && (
                            <span className="block">D: {load.trip_info.find((c) => c.tx_type === "D")?.id ?? "-"}</span>
                          )}
                        </p>
                      </ResizableTD>
                    ) : column.keyValue === "billNumber" ? (
                      <ResizableTD
                        key={indexColumn}
                        className="text-center"
                        style={{ maxInlineSize: `${column.inlineSize}px`, inlineSize: `${column.inlineSize}px` }}
                      >
                        {can({ path: PATH, can: "UPDATE" }) ? (
                          <button
                            className="underline text-[10px] font-extrabold text-blue-700 dark:hover:text-blue-500"
                            role="button"
                            onClick={() => navigate(`${PAGES.LOADS.path}/edit/${load.id}`)}
                          >
                            {load.bill_number}
                          </button>
                        ) : (
                          <span>{load.bill_number}</span>
                        )}
                      </ResizableTD>
                    ) : column.keyValue === "statusName" ? (
                      <ResizableTD
                        key={indexColumn}
                        className="text-center"
                        style={{ maxInlineSize: `${column.inlineSize}px`, inlineSize: `${column.inlineSize}px` }}
                      >
                        <p className="block text-center">
                          {can({ path: PATH, can: "UPDATE" }) ? (
                            <button
                              className="underline text-[10px] font-extrabold text-blue-700 dark:hover:text-blue-500"
                              role="button"
                              onClick={() => openModalUpdateStatus(load)}
                            >
                              {load.status.status_code}
                            </button>
                          ) : (
                            <span>{load.status.status_code}</span>
                          )}
                        </p>
                      </ResizableTD>
                    ) : column.keyValue === "driverNames" ? (
                      <ResizableTD key={indexColumn} style={{ maxInlineSize: `${column.inlineSize}px`, inlineSize: `${column.inlineSize}px` }}>
                        <p className="flex items-center gap-1">{renderDrivers(load)}</p>
                      </ResizableTD>
                    ) : column.keyValue === "truckNames" ? (
                      <ResizableTD key={indexColumn} style={{ maxInlineSize: `${column.inlineSize}px`, inlineSize: `${column.inlineSize}px` }}>
                        <p className="flex items-center gap-1">{renderTrucks(load)}</p>
                      </ResizableTD>
                    ) : column.keyValue === "trailerNames" ? (
                      <ResizableTD key={indexColumn} style={{ maxInlineSize: `${column.inlineSize}px`, inlineSize: `${column.inlineSize}px` }}>
                        <p className="flex items-center gap-1">{renderTrailers(load)}</p>
                      </ResizableTD>
                    ) : column.keyValue === "docs" ? (
                      <ResizableTD key={indexColumn} style={{ maxInlineSize: `${column.inlineSize}px`, inlineSize: `${column.inlineSize}px` }}>
                        <p className="text-center">
                          <Button className="z-0" icon onClick={() => onSelectLoad({ open: true, load })} disabled={!load.documents.length}>
                            <FaFolderOpen className="text-xs" />
                          </Button>
                        </p>
                      </ResizableTD>
                    ) : column.keyValue === "options" ? (
                      <CanSome path={PATH} can={["UPDATE", "DELETE"]} key={indexColumn}>
                        <ResizableTD style={{ maxInlineSize: `${column.inlineSize}px`, inlineSize: `${column.inlineSize}px` }} overflowHidden={false}>
                          <div className="flex gap-0.5 justify-center">
                            <Can path={PATH} can={"UPDATE"}>
                              <Dropdown renderLabel="Options" classesButton="!text-xs" direction="end">
                                <Dropdown.Item onClick={() => navigate(`${PAGES.LOADS.path}/edit/${load.id}`)} className="!px-4">
                                  <div className="flex gap-1">
                                    <FaPenToSquare className="text-sm self-center w-3.5" />
                                    <span>{$t({ id: "Edit" })}</span>
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => openRenderModal(load, "ASSIGN_DRIVER")} className="!px-4">
                                  <div className="flex gap-1">
                                    <FaUserPlus className="text-sm self-center w-3.5" />
                                    <span>{`${$t({ id: "Assign" })} ${$t({ id: "Driver" })}`}</span>
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => openRenderModal(load, "ASSIGN_TRUCK")} className="!px-4">
                                  <div className="flex gap-1">
                                    <FaTruckArrowRight className="text-sm self-center w-3.5" />
                                    <span>{`${$t({ id: "Assign" })} ${$t({ id: "Truck" })}`}</span>
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => openRenderModal(load, "ASSIGN_TRAILER")} className="!px-4">
                                  <div className="flex gap-1">
                                    <FaTrailer className="text-sm self-center w-3.5" />
                                    <span>{`${$t({ id: "Assign" })} ${$t({ id: "Trailer" })}`}</span>
                                  </div>
                                </Dropdown.Item>
                                <Can path={PATH} can={"DELETE"}>
                                  <Dropdown.Item onClick={() => handleDestroyClick(load)} className="!px-4">
                                    <div className="flex gap-1">
                                      <FaTrashCan className="text-sm self-center w-3.5" />
                                      <span>{$t({ id: "Delete" })}</span>
                                    </div>
                                  </Dropdown.Item>
                                </Can>
                              </Dropdown>
                            </Can>
                          </div>
                        </ResizableTD>
                      </CanSome>
                    ) : (
                      <ResizableTD
                        key={indexColumn}
                        className="!relative"
                        style={{ maxInlineSize: `${column.inlineSize}px`, inlineSize: `${column.inlineSize}px` }}
                      >
                        {/* @ts-ignore */}
                        <p className={clsx(column.classesChildren, "ps-2")}>{load[column.keyValue] ?? "-"}</p>
                      </ResizableTD>
                    ),
                  )}
                </ResizableTR>
              ))
            )}
          </tbody>
        </TableResizable>
      </DragDropContext>
    </>
  )
}

export default LoadsPage
