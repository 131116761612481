import { createContext, useEffect, useMemo, useReducer } from "react"

import type { IAppContext } from "./types"
import type { Load } from "@/pages/Loads/LoadType"
import { dispatchMapReducer, loadReducer, dispatchReducer, appReducer } from "@/store/reducers"
import InitialState from "@/store/appInitial.state"
import { Adapter } from "@/pages/Loads/LoadAdapter"
import { getUserFromLocalStorage } from "./AuthenticateContext"
import { useStatus } from "@/pages/Status/hooks/useStatus"
import { useTerminals } from "@/pages/Terminals/hooks/useTerminals"
import { PAGES } from "@/router/routeInitial.state"
import { Door } from "@/pages/Doors/DoorType"
import httpSocketServer from "@/libs/axiosSocketServer"

export let createFreightHandler: TClosureCallback<Load> = (callback: any) => (params: Load) => callback(params)
export let updateFreightHandler: TClosureCallback<Load> = (callback: any) => (params: Load) => callback(params)
export let destroyFreightHandler: TClosureCallback<Load> = (callback: any) => (params: Load) => callback(params)
export let addDocumentFreightHandler: TClosureCallback<unknown> = (callback: any) => (params: unknown) => callback(params)
export let toggleAssignToTripHandler: TClosureCallback<any> = (callback: any) => (params: any) => callback(params)
export let updateTripHandler: TClosureCallback<any> = (callback: any) => (params: any) => callback(params)
export let createTripHandler: TClosureCallback<unknown> = (callback: any) => (params: unknown) => callback(params)
export let getPageHistory: TClosureCallback<any> = (callback: any) => (params: any) => callback(params)
export let getMenuIconsHandler: TClosureCallback<any> = (callback: any) => (params: any) => callback(params)
export let createDoorHandler: TClosureCallback<Door> = (callback: any) => (payload: Door) => callback(payload)
export let updateDoorHandler: TClosureCallback<Door> = (callback: any) => (payload: Door) => callback(payload)
export let destroyDoorHandler: TClosureCallback<Door> = (callback: any) => (payload: Door) => callback(payload)

export const AppContext = createContext({} as IAppContext)
const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [storeDispatchMap, dispatchDispatchMap] = useReducer(dispatchMapReducer, InitialState.dispatchMap)
  const [storeLoads, dispatchLoads] = useReducer(loadReducer, InitialState.load)
  const [storeDispatch, dispatchDispatch] = useReducer(dispatchReducer, InitialState.dispatch)
  const [storeApp, dispatchApp] = useReducer(appReducer, InitialState.app)

  const { fetchAll: fetchStatus } = useStatus({ per_page: 200 })
  const { fetchAll: fetchTerminals } = useTerminals({ per_page: 200 })

  createFreightHandler = (payload: Load) => {
    dispatchLoads({ type: "ADD_FREIGHT_TO_LIST", payload })
    dispatchDispatch({
      type: "TOGGLE_ADD_TO_FREIGHTS",
      payload: {
        newItem: Adapter.loadToLoadWithType(payload, "D"),
        type: "Delivery",
        action: "ADD",
      },
    })
    dispatchDispatch({
      type: "TOGGLE_ADD_TO_FREIGHTS",
      payload: {
        newItem: Adapter.loadToLoadWithType(payload, "P"),
        type: "Pickup",
        action: "ADD",
      },
    })
  }
  updateFreightHandler = (payload: Load) => {
    dispatchLoads({ type: "UPDATE_FREIGHT_TO_LIST", payload })
    dispatchDispatch({
      type: "UPDATE_TO_FREIGHT",
      payload: {
        newItem: Adapter.loadToLoadWithType(payload, "D"),
        type: "Delivery",
      },
    })
    dispatchDispatch({
      type: "UPDATE_TO_FREIGHT",
      payload: {
        newItem: Adapter.loadToLoadWithType(payload, "P"),
        type: "Pickup",
      },
    })
    if (payload.trip_number) {
      const getTripInfo = (type: "PICKUP" | "DELIVERY") => payload.trip_number.find((tn) => tn.type === type)
      const _tripInfoDelivery_ = getTripInfo("DELIVERY")
      const _tripInfoPickup_ = getTripInfo("PICKUP")
      if (_tripInfoPickup_) {
        dispatchDispatch({
          type: "UPDATE_FREIGHT_OF_TRIP_SELECTED",
          // @ts-ignore
          payload: {
            freight: { ...payload, tx_type: "P" },
            tripId: _tripInfoPickup_.trip_number,
          },
        })
      }
      if (_tripInfoDelivery_) {
        dispatchDispatch({
          type: "UPDATE_FREIGHT_OF_TRIP_SELECTED",
          // @ts-ignore
          payload: {
            freight: { ...payload, tx_type: "D" },
            tripId: _tripInfoDelivery_.trip_number,
          },
        })
      }
    }
  }
  destroyFreightHandler = (payload: Load) => {
    const pathname = `${PAGES.LOADS.path}/edit/${payload.id}`
    if (storeApp.visitedPages.some((vp) => vp.pathname == pathname)) {
      dispatchApp({ type: "REMOVE_PAGE_OF_VISITED_PAGES", payload: { pathname } })
    }
    dispatchLoads({ type: "REMOVE_FREIGHT_TO_LIST", payload })
    dispatchDispatch({
      type: "TOGGLE_ADD_TO_FREIGHTS",
      payload: {
        newItem: Adapter.loadToLoadWithType(payload, "D"),
        freightId: payload.id,
        type: "Delivery",
        action: "REMOVE",
      },
    })
    dispatchDispatch({
      type: "TOGGLE_ADD_TO_FREIGHTS",
      payload: {
        newItem: Adapter.loadToLoadWithType(payload, "P"),
        freightId: payload.id,
        type: "Pickup",
        action: "REMOVE",
      },
    })
  }
  addDocumentFreightHandler = (payload: unknown) => {
    dispatchLoads({ type: "ADD_DOCUMENT_TO_FREIGHT", payload })
  }
  toggleAssignToTripHandler = ({ TYPE_ACTION, ...payload }: any) => {
    if (TYPE_ACTION === "FREIGHTS") {
      dispatchLoads({ type: "TOGGLE_ADD_TRIP_INFO_TO_FREIGHT", payload })
    } else if (TYPE_ACTION === "TOGGLE_ADD_TO_FREIGHTS") {
      dispatchDispatch({ type: "TOGGLE_ADD_TO_FREIGHTS", payload })
    }
  }
  updateTripHandler = ({ TYPE_ACTION, ...payload }: any) => {
    switch (TYPE_ACTION) {
      case "CURRENT_SELECTED":
        dispatchDispatch({ type: "SET_TRIP_SELECTED", payload })
        break
      case "LIST":
        dispatchDispatch({ type: "UPDATE_TRIPS", payload })
        break
      default:
        break
    }
  }
  createTripHandler = (payload: unknown) => {
    dispatchDispatch({ type: "TOGGLE_ADD_TRIP_TO_TRIPS", payload })
  }
  getPageHistory = (payload: any) => {
    dispatchApp({ type: "SET_VISITED_PAGES", payload: payload?.visitedPages || [] })
  }
  getMenuIconsHandler = (menuIcons: any) => {
    dispatchApp({ type: "LOAD_MENU_ICONS", payload: menuIcons })
  }
  createDoorHandler = (payload: Door) => {
    dispatchDispatch({ type: "ADD_DOOR", payload })
  }
  updateDoorHandler = (payload: Door) => {
    dispatchDispatch({ type: "UPDATE_DOOR", payload })
  }
  destroyDoorHandler = (payload: Door) => {
    dispatchDispatch({ type: "REMOVE_DOOR", payload })
  }

  const fetchVisitedPages = async (params: any) => {
    try {
      const { data } = await httpSocketServer.get("/visited-pages", { params })
      getPageHistory(data)
    } catch (error) {}
  }
  const fetchMenuIcons = async (params: any) => {
    try {
      const { data } = await httpSocketServer.get("/menu-icons", { params })
      getMenuIconsHandler(data)
    } catch (error) {}
  }

  const storeSetVisitedPages = async (payload: any) => {
    try {
      httpSocketServer.post("/visited-pages", payload)
    } catch (error) {}
  }

  useEffect(() => {
    const user: User = getUserFromLocalStorage()
    fetchVisitedPages({ companyId: user.company_id, userId: user.id })
    fetchMenuIcons({})
  }, [])

  const loadStatus = async () => {
    if (!storeApp.status.length) {
      fetchStatus().then((newStatus) => dispatchApp({ type: "SET_STATES", payload: { status: newStatus } }))
    }
  }

  const loadTerminals = async () => {
    if (storeApp.terminals.length) return
    fetchTerminals().then((terminals) => dispatchApp({ type: "SET_TERMINALS", payload: { terminals } }))
  }

  const iconIndexed = useMemo(() => new Map(storeApp.menuIcons.map((c) => [c.iconId, c.icon])), [storeApp.menuIcons])

  useEffect(() => {
    const user: User = getUserFromLocalStorage()
    if (storeApp.visitedPages.length > 0) {
      storeSetVisitedPages({
        companyId: user.company_id,
        userId: user.id,
        newPages: storeApp.visitedPages,
      })
    }
  }, [storeApp.visitedPages])

  const share: IAppContext = {
    storeDispatchMap,
    dispatchDispatchMap,
    storeLoads,
    dispatchLoads,
    storeDispatch,
    dispatchDispatch,
    storeApp,
    dispatchApp,
    iconIndexed,
    loadStatus,
    loadTerminals,
  }

  return <AppContext.Provider value={share}>{children}</AppContext.Provider>
}

export default AppContextProvider
