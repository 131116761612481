import { Navigate } from "react-router-dom"
import type { RouteObject } from "react-router-dom"
import { AuthLayout } from "@/layouts/AuthLayout/AuthLayout"
import { LoginPage } from "@/pages/Auth/Login/LoginPage"
import { ProtectedGuestRoutes } from "@/router/ProtectedGuestRoutes"
import { RegisterPage } from "@/pages/Auth/Register/RegisterPage"
import { NotFound } from "@pages/CodeStatus/NotFound"
import { STATIC_PAGES } from "./routeInitial.state"

export const LoginRoutes: RouteObject[] = [
  {
    path: "/",
    element: (
      <ProtectedGuestRoutes>
        <AuthLayout />
      </ProtectedGuestRoutes>
    ),
    children: [
      {
        index: true,
        element: <Navigate to={STATIC_PAGES.SIGN_IN.path} />,
      },
      {
        path: STATIC_PAGES.SIGN_IN.path,
        element: <LoginPage />,
      },
      {
        path: STATIC_PAGES.SIGN_UP.path,
        element: <RegisterPage />,
      },
      {
        path: STATIC_PAGES.FORGET_PASSWORD.path,
        element: <h1>Forget</h1>,
      },
    ],
  },
  {
    path: STATIC_PAGES.NOT_FOUND.path,
    element: <NotFound />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]
